// src/component/avatar/signup-login/interval-countdown.js
import { useRecoilState } from 'recoil';
import { preloginState } from '../../../atom/atoms';
import {useInterval} from '../../../hooks/useInterval';

export const IntervalCountdown = () => {  
    const [prelogin, setPrelogin] = useRecoilState(preloginState);

    useInterval(() => {
        if (prelogin.countDownOTP > 0) {
        setPrelogin({
            ...prelogin,
            countDownOTP: prelogin.countDownOTP - 1
        });
        }
    }, 1000);

    return (
        <>{prelogin?.countDownOTP}</>
    );
}