import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faBolt, faRocket } from "@fortawesome/free-solid-svg-icons";
import { useRecoilValue } from "recoil";
import { userState } from "../../../atom/atoms";
import { getLifestyleQuestionsByUserEmailAndDateTimeRange } from "../../../store/pouch/lifestyle-pouch";
import "./DailyPredictFatLoss.css";
import { getEndOfDayISOStringZ, getStartOfDayISOStringZ } from "../../../util/Util";
import { getHabitQuestionsByUserEmailAndDateTimeRange } from "../../../store/pouch/habit-pouch";
import LifestyleHabitHistory from './lifestyle-habit-history/LifestyleHabitHistory'; // Import the component
import LifestyleBatteryIndicator from './lifestyle-habit-history/LifestyleBatteryIndicator'; // Import the new component

const DailyPredictFatLoss = () => {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const [lifestyleScore, setLifestyleScore] = useState(0);
  const [batteryFillWidth, setBatteryFillWidth] = useState("0%");

  useEffect(() => {
    const fetchLifestyleScore = async () => {

      const lifestyleQuestions = await getLifestyleQuestionsByUserEmailAndDateTimeRange(
        user?.userEmail,
        getStartOfDayISOStringZ(),
        getEndOfDayISOStringZ()
      );
      const habitQuestions = await getHabitQuestionsByUserEmailAndDateTimeRange(
        user?.userEmail,
        getStartOfDayISOStringZ(),
        getEndOfDayISOStringZ()
      );
      const score = lifestyleQuestions.reduce((total, question) => {
        return total + (question.lifestyleQuestionResponse === "yes" ? 5 : 0);
      }, 0) + habitQuestions.reduce((total, question) => {
        return total + (question.answer === "no" ? 5 : 0);
      }, 0);

      setLifestyleScore(score);
    };
    
    fetchLifestyleScore();
  }, [user.userEmail]);
  // setBatteryFillWidth(`${Math.min(score, 100)}%`);

  const handleBack = () => {
    navigate("/daily");
  };

  return (
    <>
      <div className="daily-predict-fatloss-container">
        <button onClick={handleBack} className="back-button">
          <FontAwesomeIcon icon={faChevronLeft} /> Back
        </button>
        <LifestyleBatteryIndicator lifestyleScore={lifestyleScore} /> {/* Use the battery indicator */}
        <div className="battery-label">
          <span>0</span>
          <span>{lifestyleScore}%</span>
          <span>100</span>
        </div>
        {/* <button className="font-bold text-xl px-8 py-5 power-up-button mt-3 bg-blue-400 text-white rounded-xl shadow-md shadow-blue-800/35"> */}
          <span className="mt-5 mb-2 text-blue-500 font-bold text-2xl">
            To Power Up Fat Loss
          </span>
          <button
          onClick={() => navigate('/daily')} // Navigate to '/daily' on click
          className="bg-blue-400 text-white shadow-md shadow-blue-800/35 
                     min-w-[40vw] max-w-[80vw] py-3 rounded-lg flex items-center justify-center mx-auto" // Added button styles
        >
          <FontAwesomeIcon icon={faRocket} className="ml-2 text-3xl mr-2" /> {/* Rocket icon */}
          <div className="text-2xl mr-3">
          Do More Missions
          </div>
        </button>

        <LifestyleHabitHistory /> {/* Add Lifestyle Habit History here */}


        
      </div>
    </>
  );
};

export default DailyPredictFatLoss;
