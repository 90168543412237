import { VxApiService } from './VxApiService';
import axios from 'axios';

class VxOAuthService extends VxApiService {
  constructor(version = "v0") {
    super(version);
  }

  async getTokenByState() {
    const state = localStorage.getItem('frontEndState');
    
    try {
      const response = await axios.get(`${this.baseURL}/oauth/tokenByState?state=${state}`);
      debugger;
      return response.data;
    } catch (error) {
      console.error('Failed to get token by state:', error);
      // throw error;
    }
  }

  async requestEmailOtp(email) {
    try {
      const response = await axios.post(`${this.baseURL}/oauth/email-otp`, {
        email: email,
      }, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      return response.data; // Return the response data
    } catch (error) {
      console.error('Failed to request pre-OTP:', error);
    //   throw error; // Rethrow the error for handling in the calling function
    }
  }

  async verifyEmailOtp(email, otp) {
    try {
      const response = await axios.post(`${this.baseURL}/oauth/email-otp-login`, {
        email: email,
        otp: otp,
      }, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      return response.data; // Return the response data
    } catch (error) {
      console.error('Failed to verify email OTP:', error);
      // throw error; // Rethrow the error for handling in the calling function
    }
  }

  async logoutGoogle(accessToken){
    try {
      const response = await axios.get(`${this.baseURL}/oauth/logout/google`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data; // Return the response data if needed
    } catch (error) {
      console.error("Error logging out from Google:", error);
      throw error; // Rethrow the error for handling in the calling function
    }
  }
  
  
}

const vxOAuthInstance = process.env.REACT_APP_ENABLE_API_V1 === "true" ? new VxOAuthService("v1") : new VxOAuthService();
export default vxOAuthInstance;