// src/component/mission/DailyMore.js
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  coinEarnedAmountState,
  coinEarnedAnimationState,
} from "../../atom/coin/coin-atoms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faSquare,
  faCheckSquare,
  faComments,
  faCalculator,
  faTrophy,
  faChartLine,
  faCalendar,
  faGlobe,
  faDumbbell,
  faPeopleGroup,
  faFire,
  faStar,
  faStarHalfAlt,
  faPencil,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import {
  getEndOfDayISOStringZ,
  getLocalDateOnly,
  getStartOfDayISOStringZ,
} from "../../util/Util";
import { calorieAtom } from "../../atom/fatloss/calorie-atom";
import { getCaloriesByUserEmailAndDateTimeString } from "../../store/pouch/calorie-pouch";
import { userState } from "../../atom/atoms";
import {
  getAllChat,
  getChatsByUserEmail,
  getChatsByUserEmailAndDateTimeString,
} from "../../store/pouch/chat-pouch";
import { getMealsByUserEmailAndEpoch } from "../../store/pouch/meal-pouch";
import { getCoinById } from "../../store/pouch/coin-pouch";

const DailyMore = ({promptForNotificationPermission}) => {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const setCoinEarnedAmount = useSetRecoilState(coinEarnedAmountState);
  const setCoinEarnedAnimation = useSetRecoilState(coinEarnedAnimationState);
  const exerciseState = useRecoilValue(calorieAtom);
  const [exerciseDoneToday, setExerciseDoneToday] = useState(false);
  const [chatDoneToday, setChatDoneToday] = useState(false);
  const [mealAddedToday, setMealAddedToday] = useState(false);
  const [checkinDoneToday, setCheckinDoneToday] = useState(false);
  const effectRan = useRef(false);
  const [isClosing, setIsClosing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [starRating, setStarRating] = useState(5); // Set default star rating to 5
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);


  const handleTrackExercise = () => {
    navigate("/exercise");
  };

  const handleTrackBMI = () => {
    navigate("/track-bmi");
  };
  const handleTalkToHealthChat = () => {
    navigate("/chatbot");
  };
  const handleCountCalories = () => {
    navigate("/mealsnap");
  };
  const handleMetabolism = () => {
    navigate("/calorie");
  };
  const handleViewNextLevel = () => {
    navigate("/next-level");
  };

  const handleAppointment = () => {
    navigate("/appointment-booking");
  };

  const handleCheckin = () => {
    if (checkinDoneToday) {
      showModalWithMessage("You already checked in today.\r\n Please check in again tomorrow.");
    } else {
      navigate("/daily-checkin");
    }
  };
  
    useEffect(() => {
      if (effectRan.current === false) {
        const asyncWrapper = async () => {
  
          let params = [
            user?.userEmail,
            getStartOfDayISOStringZ(),
            getEndOfDayISOStringZ(),
          ];
          let exerciseToday = await getCaloriesByUserEmailAndDateTimeString(
            user?.userEmail,
            getStartOfDayISOStringZ(),
            getEndOfDayISOStringZ(),
            "exercise",
          );
          setExerciseDoneToday(!!exerciseToday.length);
  
          let chatToday = await getChatsByUserEmailAndDateTimeString(...params);
          setChatDoneToday(!!chatToday.length);
  
          let mealToday = await getMealsByUserEmailAndEpoch(...params);
          setMealAddedToday(!!mealToday.length);
  
          // Check if the coin exists for checkin
          let checkinCoin = await getCoinById(`${user?.userEmail}-CHECKIN-${getLocalDateOnly()}`);
          setCheckinDoneToday(!!checkinCoin);
        };
        asyncWrapper();
      }
    }, [user?.userEmail]);

  const showModalWithMessage = (message) => {
    setModalMessage(message);
    setShowModal(true);
    setTimeout(() => {
      closeModal();
    }, 5000);
  };

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setShowModal(false);
      setIsClosing(false);
    }, 360); // Match this duration with the CSS transition duration
  };

  // Function to handle star click
  const handleStarClick = (rating) => {
    setStarRating(rating);
  };

  // Function to submit feedback
  const handleSubmitFeedback = () => {
    // Navigate to feedback page with star rating
    navigate(`/feedback?starRating=${starRating}`);
    closeFeedbackModal();
  };

  // Function to close feedback modal
  const closeFeedbackModal = () => {
    setShowFeedbackModal(false);
    setStarRating(0); // Reset star rating
  };

  return (
    <div className="flex flex-col items-center w-full mt-4">
      <h2 className="text-2xl font-bold text-blue-500 mb-4">More Missions Coming Soon..</h2>
      <div className="shadow-lg shadow-gray-500 mb-4">
        <img
          src={`${process.env.PUBLIC_URL}/daily2/09.1-healthy-lifestyle-more.jpg`}
          alt="More Mission"
          className=""
        />
      </div>
      
      {/* <div className="flex w-full">
        <div className="w-1/4 flex items-center mb-3">
          <FontAwesomeIcon
            icon={checkinDoneToday ? faCheckSquare : faSquare}
            className={`w-full ${checkinDoneToday ? "text-green-500" : "text-gray-400"}`}
            size="lg"
          />
        </div>
        <div className="w-3/4 flex items-center justify-center mb-3">
          <button
            onClick={handleCheckin}
            className={`w-full mr-3 py-3 rounded-lg transition-all duration-300 flex items-center ${
              checkinDoneToday
                ? "border border-blue-500 text-blue-500 font-normal shadow-lg shadow-blue-500/30"
                : "font-bold text-white shadow-md bg-blue-400 shadow-blue-800/35"
            }`}
          >
            <FontAwesomeIcon icon={faGlobe} className="ml-5 mr-2" />
            <span className="flex-1 text-center">Checkin Online</span>
          </button>
        </div>
      </div>
      */}

      <div className="flex flex-row justify-between mb-3">
        
          <button
            onClick={handleTrackExercise}
            className="w-[30vw] py-4 rounded-lg transition-all duration-300 flex flex-col items-center 
              font-bold text-white shadow-md bg-blue-400 shadow-blue-800/35 mr-1"
          >
            <FontAwesomeIcon icon={faDumbbell} className="text-3xl" /> {/* Increased icon size */}
            <span className="text-center text-lg">Track My Exercise</span> {/* Centered text and increased font size */}
          </button>
        
          <button
            onClick={handleMetabolism}
            className="w-[30vw] py-4 rounded-lg transition-all duration-300 flex flex-col items-center 
              font-bold text-white shadow-md bg-blue-400 shadow-blue-800/35 ml-1"
          >
            <FontAwesomeIcon icon={faFire} className="text-3xl" /> {/* Increased icon size and added margin-bottom */}
            <span className="text-center text-lg mt-auto">My Metabolism</span> {/* Centered text and aligned to bottom */}
          </button>
      </div>
      
      {/*
      <div className="flex w-full">
        <div className="w-1/4 flex items-center mb-3">
          <FontAwesomeIcon
            icon={chatDoneToday ? faCheckSquare : faSquare}
            className={`w-full ${chatDoneToday ? "text-green-500" : "text-gray-400"}`}
            size="lg"
          />
        </div>
        <div className="w-3/4 flex items-center mb-3">
          <button
            onClick={handleTalkToHealthChat}
            className="w-full py-3 font-bold text-white rounded-lg shadow-md bg-blue-400 shadow-blue-800/35 transition-opacity duration-1000 mr-3 flex items-center"
          >
            <FontAwesomeIcon icon={faComments} className="ml-5 mr-2" />
            <span className="flex-1 text-center">Talk to healthChat</span>
          </button>
        </div>
      </div>
      <div className="flex w-full">
        <div className="w-1/4 flex items-center mb-3">
          <FontAwesomeIcon
            icon={mealAddedToday ? faCheckSquare : faSquare}
            className={`w-full ${mealAddedToday ? "text-green-500" : "text-gray-400"}`}
            size="lg"
          />
        </div>
        <div className="w-3/4 flex items-center justify-center mb-3">
          <button
            onClick={handleCountCalories}
            className="w-full py-3 font-bold text-white rounded-lg shadow-md bg-blue-400 shadow-blue-800/35 transition-opacity duration-1000 mr-3 flex items-center"
          >
            <FontAwesomeIcon icon={faCalculator} className="ml-5 mr-2" />
            <span className="flex-1 text-center">Count Calories</span>
          </button>
        </div>
      </div>

      <div className="flex w-full">
        <div className="w-1/4 flex items-center mb-3">
        </div>
        <div className="w-3/4 flex items-center justify-center mb-3">
          <button
            onClick={() => navigate("/fat-loss")}
            className="w-full py-3 font-bold text-white rounded-lg shadow-md bg-blue-400 shadow-blue-800/35 transition-opacity duration-1000 mr-3 flex items-center"
          >
            <FontAwesomeIcon icon={faChartLine} className="ml-5 mr-2" />
            <span className="flex-1 text-center">Predict Fat Loss</span>
          </button>
        </div>
      </div>

      <div className="flex w-full">
        <div className="w-1/4 flex items-center mb-3"></div>
        <div className="w-3/4 flex items-center justify-center mb-3">
          <button
            onClick={handleAppointment}
            className="w-full py-3 font-bold text-white rounded-lg shadow-md bg-blue-400 shadow-blue-800/35 transition-opacity duration-1000 mr-3 flex items-center"
          >
            <FontAwesomeIcon icon={faCalendar} className="ml-5 mr-2" />
            <span className="flex-1 text-center">Appointment (Research)</span>
          </button>
        </div>
      </div>
      <div className="flex w-full">
        <div className="w-1/4 flex items-center mb-3"></div>
        <div className="w-3/4 flex items-center justify-center mb-3">
          <button
            onClick={promptForNotificationPermission}
            className="w-full py-3 font-bold text-white rounded-lg shadow-md bg-blue-400 shadow-blue-800/35 transition-opacity duration-1000 mr-3 flex items-center"
          >
            <FontAwesomeIcon icon={faBell} className="ml-5 mr-2" />
            <span className="flex-1 text-center">Subscribe My Reminders</span>
          </button>
          <div id="webpushr-subscription-button"></div>
        </div>
      </div> */}

      <div className="flex flex-row justify-between mb-3"> {/* Use flex for horizontal alignment */}
          <button
            onClick={handleViewNextLevel}
            className="w-[30vw] py-4 rounded-lg transition-all duration-300 flex flex-col items-center 
              font-bold text-white shadow-md bg-blue-400 shadow-blue-800/35 mr-1" 
          >
            <FontAwesomeIcon icon={faTrophy} className="text-3xl mb-1" /> {/* Increased icon size and added margin-bottom */}
            <span className="text-center text-lg mt-auto">View Next Level</span> {/* Centered text and aligned to bottom */}
          </button>

          <button
            onClick={() => setShowFeedbackModal(true)}
            className="w-[30vw] py-4 rounded-lg transition-all duration-300 flex flex-col items-center 
              font-bold text-white shadow-md bg-blue-400 shadow-blue-800/35 ml-1" 
          >
            <FontAwesomeIcon icon={faPencil} className="text-3xl mb-1" /> {/* Increased icon size and added margin-bottom */}
            <span className="text-center text-lg mt-auto">Give Feedback</span> {/* Centered text and aligned to bottom */}
          </button>
      </div>

      {showModal && (
        <div 
          className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 ${isClosing ? 'opacity-0' : 'opacity-100'}`}
          onClick={closeModal}
        >
          <div 
            className={`bg-white p-6 rounded-lg shadow-xl relative transition-all duration-300 ${isClosing ? 'opacity-0 scale-95' : 'opacity-100 scale-100'}`}
            onClick={(e) => e.stopPropagation()}
          >
            <p className="text-lg font-semibold text-center whitespace-pre-line">
              {modalMessage}
            </p>
          </div>
        </div>
      )}

      {/* Feedback modal */}
      {showFeedbackModal && (
        <div className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50`}>
          <div className="bg-white p-6 rounded-lg shadow-xl relative">
            <h3 className="text-lg font-semibold text-center text-blue-500">Rate Us</h3>
            <div className="flex justify-center my-4">
              {[1, 2, 3, 4, 5].map((star) => (
                <FontAwesomeIcon
                  key={star}
                  icon={star <= starRating ? faStar : faStarRegular}
                  onClick={() => handleStarClick(star)}
                  className={`cursor-pointer ${star <= starRating ? "text-yellow-300" : "text-yellow-400"}`}
                />
              ))}
            </div>
            <div className="flex justify-between">
              <button onClick={handleSubmitFeedback} className="bg-blue-500 text-white px-4 py-2 rounded mr-2">{"  "}Submit{"  "}</button>
              <button onClick={closeFeedbackModal} className="bg-gray-300 px-4 py-2 rounded">Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DailyMore;
