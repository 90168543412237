import React from 'react';
import { Navigate } from 'react-router-dom';
import useTokenCheckVx from '../hooks/useTokenCheckVx';

export const ProtectedRoute = ({ children }) => {
  const { isApiConnected, isLoading } = useTokenCheckVx();

  if (isLoading) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  if (!isApiConnected) {
    return <Navigate to="/signup-login" replace />;
  }

  return children;
};
