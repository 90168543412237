// Function to parse the query string from the URL
export const getQueryStringParams = () => {
    const queryString = window.location.search; // Get the query string from the URL
    const urlParams = new URLSearchParams(queryString); // Create a URLSearchParams object

    // Convert URLSearchParams to a plain object
    const params = {};
    for (const [key, value] of urlParams.entries()) {
        params[key] = value;
    }
    return params; // Return the parameters as an object
};

// Example usage
// const params = getQueryStringParams();
// console.log(params); // { access_token: '...', refresh_token: '...', state: '...' }
