// src/component/ideal/IdealWeight.js
import React from 'react';

const IdealWeight = ({ weight, targetWeight, targetBMI }) => {
  const displayWeight = (targetWeight && targetWeight < weight) ? targetWeight : weight; // Use targetWeight if it exists, otherwise use ideal weight
  return (
    <div className="bg-blue-400 text-white p-4 rounded-lg shadow-md shadow-blue-800/35">
      <h2 className="text-lg font-semibold mb-2">
        {(targetWeight && targetWeight < weight) ? 'Target Weight': 'Ideal Weight'}
        <span className='text-green-300'
        style={{
          textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)'
        }}>
          <br/>(BMI {(targetWeight && targetWeight < weight) ? targetBMI : 23.5})
        </span>
      </h2>
      <p className="text-xl font-bold text-green-300">{displayWeight} kg</p>
    </div>
  );
};

export default IdealWeight;
