import React, { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { surveyAtom } from "../../../atom/survey/survey-atoms";
import {
  completedStartScreenState,
  stateOfIntroductionState,
  userState,
} from "../../../atom/atoms";
import { useNavigate } from "react-router-dom";
import FAQ from "../../faq/faq";
import HomeTips from "./HomeTips";
import { coinEarnedTodayState } from "../../../atom/coin/coin-today-atom";
import { getCoinAmountEarnedToday } from "../../../util/CoinClaiming";

const HomeMenu = () => {
  // const user = useRecoilValue(userState);
  // const surveyState = useRecoilValue(surveyAtom);
  // const stateOfIntroduction = useRecoilValue(stateOfIntroductionState);
  // const setCoinEarnedToday = useSetRecoilState(coinEarnedTodayState);
  // const [isFadingOut, setIsFadingOut] = useState(false);
  // const navigate = useNavigate();
  // const handleClick = () => {
  //   const asyncWrapper = async () => {
  //     setIsFadingOut(true);
  //     setTimeout(() => {
  //       if (!surveyState.isComplete && stateOfIntroduction !== "20") {
  //         navigate("/testchat");
  //       } else if (!surveyState.isComplete) {
  //         navigate("/survey");
  //       } else {
  //         debugger;
  //         setCoinEarnedToday(getCoinAmountEarnedToday(user?.userEmail));
  //         navigate("/daily");
  //       }
  //     }, 1000);
  //   };
  //   asyncWrapper();
  // };

  // const handleReplay = () => {
  //   setIsFadingOut(true);
  //   setTimeout(() => {
  //     navigate("/start");
  //   }, 1000);
  // };
  // return (
  //   <>
  //     <div className=" mt-4">
  //       <div className="w-full flex justify-center px-4 mb-4">
  //         <button
  //           onClick={handleClick}
  //           className={`px-6 py-3 font-bold text-white rounded-lg  bg-blue-400 shadow-lg shadow-blue-800/35  transition-opacity duration-1000 ${
  //             isFadingOut ? "opacity-0" : "opacity-100"
  //           }`}
  //         >
  //           Get Started
  //         </button>
  //       </div>
  //       <div className="w-full flex justify-center px-4 mb-4">
  //         <button
  //           onClick={handleReplay}
  //           className={`px-6 py-3 font-bold text-white rounded-lg bg-blue-400 shadow-lg shadow-blue-800/35 transition-opacity duration-1000 ${
  //             isFadingOut ? "opacity-0" : "opacity-100"
  //           }`}
  //         >
  //           Replay Intro
  //         </button>
  //       </div>
  //     </div>
  //     <FAQ />
  //     <HomeTips />
  //   </>
  // );
};

export default HomeMenu;
