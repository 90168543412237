import { endOfDay, startOfDay } from "date-fns";
import {
  format,
  toZonedTime as utcToZonedTime,
  zonedTimeToUtc,
} from "date-fns-tz";
import {
  subDays,
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds,
} from "date-fns";

export const formatTime = (date) => {
  if (!(date instanceof Date) || isNaN(date)) {
    return "just now";
  }

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;
  return `${hours}:${minutesStr} ${ampm}`;
};

// export const convertYYYYMMDDToDateObj = (dateString) => {
//   const [year, month, day] = dateString.split("-");
//   return new Date(year, month - 1, day);
// };

// export const convertYYYYMMDDToDateObjEOD = (dateString) => {
//   const [year, month, day] = dateString.split("-");
//   return new Date(year, month - 1, day, 23, 59, 59, 999);
// };

// export const getStartOfDayISOString = () => {
//   const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//   const now = utcToZonedTime(new Date(), userTimeZone);
//   const startOfDayDate = startOfDay(now);
//   const todayStartOfDayISOString = format(
//     startOfDayDate,
//     "yyyy-MM-dd'T'00:00:00.000XXX",
//     { timeZone: userTimeZone },
//   );
//   return todayStartOfDayISOString;
// };
export const getStartOfDayISOStringZ = () => {
  const now = new Date();
  // const yesterdayUTC = subDays(now, 1);
  const startOfDayDate = setMilliseconds(
    setSeconds(setMinutes(setHours(now, 0), 0), 0),
    0,
  );
  const todayStartOfDayISOStringZ = startOfDayDate.toISOString();
  return todayStartOfDayISOStringZ;
};

export const getEndOfDayISOStringZ = () => {
  const now = new Date();
  const endOfDayDate = setMilliseconds(
    setSeconds(setMinutes(setHours(now, 23), 59), 59),
    999,
  );
  const todayEndOfDayISOStringZ = endOfDayDate.toISOString();
  return todayEndOfDayISOStringZ;
};

export const getEndOfDayISOStringZyyyyMMdd = (yyyy_MM_dd) => {
  const now = new Date(yyyy_MM_dd);
  const endOfDayDate = setMilliseconds(
    setSeconds(setMinutes(setHours(now, 23), 59), 59),
    999,
  );
  const todayEndOfDayISOStringZ = endOfDayDate.toISOString();
  return todayEndOfDayISOStringZ;
};

// export const getEndOfDayISOString = () => {
//   const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//   const now = utcToZonedTime(new Date(), userTimeZone);
//   const endOfDayDate = endOfDay(now);
//   const todayEndOfDayISOString = format(
//     endOfDayDate,
//     "yyyy-MM-dd'T'00:00:00.000XXX",
//     { timeZone: userTimeZone },
//   );
//   return todayEndOfDayISOString;
// };

export const getLocalDateOnly = () => {
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  return new Date(new Date().getTime() - timezoneOffset)
    .toISOString()
    .slice(0, 10);
};

const getSODDateObj = () => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const now = utcToZonedTime(new Date(), userTimeZone);
  const startOfDayDate = startOfDay(now);
  return new Date(startOfDayDate);
};

const getEODDateObj = () => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const now = utcToZonedTime(new Date(), userTimeZone);
  const endOfDayDate = endOfDay(now);
  return new Date(endOfDayDate);
};

// export const getLocalDateTime = () => {
//   const timezoneOffset = new Date().getTimezoneOffset() * 60000;
//   return new Date(new Date().getTime() - timezoneOffset).toISOString();
// };

// export const getLocalDateTimeObj = () => {
//   const timezoneOffset = new Date().getTimezoneOffset() * 60000;
//   return new Date(new Date().getTime() - timezoneOffset);
// };
//
export const buildConversation = (
  timestamp,
  text,
  userId,
  userName,
  avatar = null,
) => {
  return avatar
    ? {
        text,
        user: {
          id: userId,
          name: userName,
          avatar,
        },
        timestamp,
      }
    : {
        text,
        user: {
          id: userId,
          name: userName,
        },
        timestamp,
      };
};

export const getArrayByStateOfIntroduction = (stateOfIntroduction) => {
  switch (stateOfIntroduction) {
    case "10":
      return {
        text: "🙋‍♀️ Hi, Nice to meet you, I am healthChat!",
        user: {
          id: "healthChat",
          name: "healthChat",
          avatar: `${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`,
        },
        timestamp: new Date(),
      };
    case "20a":
      return {
        text: "Tell me more",
        user: {
          id: "user01",
          name: "You",
        },
        timestamp: new Date(),
      };
    case "20b":
      return {
        text: "👍 Ok..I'm healthChat.",
        user: {
          id: "healthChat",
          name: "healthChat",
          avatar: `${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`,
        },
        timestamp: new Date(),
      };
    case "20c":
      return {
        text: "Healthy Eating and Lifestyle Transformation Hub Chatbot is my full name. I am your personal health assistant specialised in Fat Loss.",
        user: {
          id: "healthChat",
          name: "healthChat",
          avatar: `${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`,
        },
        timestamp: new Date(),
      };
    case "20d":
      return {
        text: "I would like to invite you to participate in our survey!",
        user: {
          id: "healthChat",
          name: "healthChat",
          avatar: `${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`,
        },
        timestamp: new Date(),
      };
    default:
      return {
        text: "Hi! 👋..",
        user: {
          id: "healthChat",
          name: "healthChat",
          avatar: `${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`,
        },
        timestamp: new Date(),
      };
  }
};

export const getQuestionsArrayWithNickname = (nickname) => [
  {
    num: 100,
    title: "Read Food Labels",
    question: `${nickname}, did you read your food labels today?`,
  },
  {
    num: 200,
    title: "Track Calories",
    question: `${nickname}, did you track calories today?`,
  },
  {
    num: 300,
    title: "Stick to Calorie Goal",
    question: `${nickname}, did you stick to your calorie goal?`,
  },
  {
    num: 400,
    title: "Adequate Sleep",
    question: `Did you have your adequate night sleep of ≥7 hours, ${nickname}?`,
  },
  {
    num: 500,
    title: "Physical Activity",
    question: `Did you find new opportunities for physical activity today?`,
  },
  {
    num: 600,
    title: "30mins Exercise",
    question: `Did you have ≥30 min of exercise today?`,
  },
  {
    num: 700,
    title: "10,000 Steps",
    question: `Did you walk ≥10,000 steps today?`,
  },
  {
    num: 800,
    title: "Healthy Meals",
    question: `Did you modify meals in a healthy way today (e.g. more vege, less carb & fat)?`,
  },
  {
    num: 900,
    title: "Drink 1L Plain Water",
    question: `Did you drink ≥1 litre plain water today?`,
  },
  {
    num: 1000,
    title: "Meal Planning",
    question: `Did you plan your meals today?`,
  },
];
