// src/component/mission/sub/lifestyle-habit-history/LifestyleHabitHistory.js

import React, { useEffect, useState } from 'react';
import vxInstance from '../../../../api/VxApiService';
import { getEndOfDayISOStringZ, getLocalDateOnly, getStartOfDayISOStringZ } from '../../../../util/Util';
import { useRecoilState } from 'recoil';
import { userState } from '../../../../atom/atoms';
import { getUserObj } from '../../../../store/pouch/user-pouch';
import { faBatteryEmpty, faBatteryFull, faBatteryHalf, faBatteryQuarter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LifestyleHabitHistory = () => {
    const [user, setUser] = useRecoilState(userState);
    const [powerUps, setPowerUps] = useState(null);
    
    const getSevenDaysAgo = () => {
      const date = new Date(getStartOfDayISOStringZ()); // Get the current date
      date.setDate(date.getDate() - 7); // Subtract 7 days
    
      // Format the date to yyyy-MM-dd
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(date.getDate()).padStart(2, '0');
    
      return `${year}-${month}-${day}`; // Return formatted date
    };
    // Sample data for the lifestyle habits
    // const habits = [
    //     { name: 'Reading Food Labels', value: 80, color: 'bg-blue-200' }, // Light blue
    //     { name: 'Tracking Calories', value: 70, color: 'bg-green-200' }, // Light green
    //     { name: 'Keeping Calorie Goals', value: 60, color: 'bg-orange-200' }, // Orange
    // ];
    useEffect(()=> {
        const asyncFn = async () => {
            
            let userObj = await getUserObj("active");
            let startDate = getSevenDaysAgo();
            let endDate = getLocalDateOnly();
            let lifestyleData = await vxInstance.filterLifestyleByDateRange(userObj?.accessToken, userObj?.refreshToken, startDate, endDate );
            
            console.log(lifestyleData);
            let powerUps = {};
            
            for (const key in lifestyleData?.lifestyleData) {
                const entry = lifestyleData?.lifestyleData[key];
                if(entry.lifestyleQuestionDate){
                    const date = entry.lifestyleQuestionDate; // Get the date
                    const response = entry.lifestyleQuestionResponse; // Get the response

                    // Initialize power-up for the date if it doesn't exist
                    if (!powerUps[date]) {
                        powerUps[date] = { totalPowerUp: 0, count: 0 };
                    }

                    // Increase power-up if the response is "yes"
                    if (response === "yes") {
                        powerUps[date].totalPowerUp += 5; // Increase power-up by 5%
                    }
                    powerUps[date].count += 1; // Count the number of responses
                }else if(entry.habitQuestionDate){
                    const date = entry.habitQuestionDate;
                    const response = entry.answer;
                    if (!powerUps[date]) {
                        powerUps[date] = { totalPowerUp: 0, count: 0 };
                    }
                    if (response === "no") {
                        powerUps[date].totalPowerUp += 5;
                    }
                    powerUps[date].count += 1;
                }
            }
            
            const sortedEntries = Object.entries(powerUps).sort((a, b) => new Date(b[0]) - new Date(a[0]));
    
            powerUps = Object.fromEntries(sortedEntries);;
            setPowerUps(powerUps);
        };
        asyncFn();
    }, [setPowerUps]);

  return (
    <>
        <div className="lifestyle-habit-history">
            {/* <h2 className="mt-3 text-xl font-bold mb-4">My Weekly Lifestyle</h2>
            <div className="space-y-2">
                {habits?.map((habit, index) => (
                <div key={index} className="flex items-center">
                    <span className="w-1/2 text-left">{habit.name}</span>
                    <div className="w-1/2 bg-gray-200 rounded-full h-4">
                    <div
                        className={`h-full rounded-full ${habit.color}`}
                        style={{ width: `${habit.value}%` }}
                    />
                    </div>
                </div>
                ))}
            </div> */}
            <>
            <h2 className="text-xl font-bold mb-4">Recent PowerUps</h2>
                {(powerUps) &&  <table className="min-w-full">
                    <thead>
                    <tr>
                        <th className="text-left">Date</th>
                        <th className="text-left">PowerUp</th>
                        <th className="text-left">Score</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.entries(powerUps)?.map(([date, { totalPowerUp }]) => {
                        // Determine the color and icon based on totalPowerUp
                        let batteryIcon;
                        let colorClass;

                        if (totalPowerUp <= 20) {
                        batteryIcon = faBatteryEmpty;
                        colorClass = 'text-red-500';
                        } else if (totalPowerUp <= 40) {
                        batteryIcon = faBatteryQuarter;
                        colorClass = 'text-orange-500';
                        } else if (totalPowerUp <= 60) {
                        batteryIcon = faBatteryHalf;
                        colorClass = 'text-yellow-500';
                        } else {
                        batteryIcon = faBatteryFull;
                        colorClass = 'text-green-500';
                        }

                        return (
                        <tr key={date}>
                            <td>{date}</td>
                            <td>
                            <FontAwesomeIcon icon={batteryIcon} className={colorClass} />
                            </td>
                            <td>{totalPowerUp}%</td>
                        </tr>
                        );
                    })}
                    </tbody>
                </table> }

            </>
        </div>
    </>
  );
};

export default LifestyleHabitHistory;
