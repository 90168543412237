import React, { useState, useRef, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { mealAtom } from "../../atom/meal/meal-atom";
import { addMealItem, mealDB, getMealsByUserEmailAndEpoch } from "../../store/pouch/meal-pouch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { getCoinById } from "../../store/pouch/coin-pouch";
import { userState } from "../../atom/atoms";
import {
  getEndOfDayISOStringZ,
  getLocalDateOnly,
  getStartOfDayISOStringZ,
} from "../../util/Util";
import { ClaimCoins, MyCoinHistory } from "../../util/CoinClaiming";
import {
  coinEarnedAmountState,
  coinEarnedAnimationState,
} from "../../atom/coin/coin-atoms";
import { coinEarnedTodayState } from "../../atom/coin/coin-today-atom";
import vxInstance from "../../api/VxApiService";

const MealCrop = () => {
  const user = useRecoilValue(userState);
  const [imageSrc, setImageSrc] = useState(null);
  const cropperRef = useRef(null);
  const navigate = useNavigate();
  const [mealState, setMealState] = useRecoilState(mealAtom);
  const effectRan = useRef(false);
  const setCoinEarnedAmount = useSetRecoilState(coinEarnedAmountState);
  const setCoinEarnedAnimation = useSetRecoilState(coinEarnedAnimationState);
  const setCoinEarnedToday = useSetRecoilState(coinEarnedTodayState);

  useEffect(() => {
    if (effectRan.current === false) {
      const fetchImage = async () => {
        if (mealState?.mealSnappedId) {
          try {
            const doc = await mealDB.get(mealState?.mealSnappedId);
            if (
              doc.mealAttachmentB64Array &&
              doc.mealAttachmentB64Array.length > 0
            ) {
              setImageSrc(doc.mealAttachmentB64Array[0]);
            }
          } catch (error) {
            console.error("Error fetching meal data:", error);
          }
        } else {
          //please retake picture
          navigate("/mealsnap");
        }
        effectRan.current = true;
      };
      fetchImage();
    }
  }, [mealState?.mealSnappedId, navigate]);

  const handleCropTemp = () => {
    const asyncWrapper = async () => {};
    asyncWrapper();
  };

  const handleCrop = () => {
    const asyncWrapper = async () => {
      const cropper = cropperRef.current?.cropper;
      if (cropper) {
        const croppedCanvas = cropper.getCroppedCanvas({
          width: 128,
          height: 128,
        });
        const croppedImage = croppedCanvas.toDataURL("image/jpeg");

        // Convert the cropped image to a File object
        const blob = await new Promise((resolve) => croppedCanvas.toBlob(resolve, "image/jpeg"));
        const file = new File([blob], "croppedImage.jpg", { type: "image/jpeg" });

        // Create the request object
        const request = {
          userEmail: user?.userEmail,
          oriFileName: "croppedImage.jpg",
          oriMimeType: "image/jpeg",
        };

        // Call the API to upload the cropped image
        const accessToken = user?.accessToken;
        const refreshToken = user?.refreshToken;
        const uploadResponse = await vxInstance.uploadSingleFileForMeal(accessToken, refreshToken, file, request);
        console.log("Upload Single File Response:", uploadResponse);

        // Call the API to analyze the cropped image
        const analyzeResponse = await vxInstance.analyzeCalories(accessToken, refreshToken, croppedImage);
        console.log("Analyze Calories Response:", analyzeResponse);

        const doc = await mealDB.get(mealState?.mealSnappedId);
        if (doc && mealState?.mealSnappedId) {
          let todayDate = getLocalDateOnly();
          await addMealItem({
            _id: mealState?.mealSnappedId,
            mealId: doc?.mealId,
            userEmail: user?.userEmail,
            mealType: doc?.mealType,
            mealDate: todayDate,
            mealDateTime: doc?.mealDateTime,
            mealEpoch: doc?.mealEpoch,
            mealAttachmentB64Array: [croppedImage],
            mealIsDeleted: false,
            mealInfo: analyzeResponse?.meal_info,
            mealSummary: analyzeResponse?.summary,
            mealSentence: analyzeResponse?.summary_sentence,
            mealCalories: analyzeResponse?.total_calories,
            mealFileMame: uploadResponse?.oriFileName,
            mealGeneratedFileName: uploadResponse?.generatedFileName,
            mealCloudPath: uploadResponse?.cloudPath,
          });

          // Retrieve calorie data
          let calorieData = await getMealsByUserEmailAndEpoch(user?.userEmail, null, null);

          // Remove mealAttachmentB64Array from each item
          let jsonCalorie = {
            calorieData: calorieData.map(item => {
              const { mealAttachmentB64Array, ...rest } = item;
              return rest;
            })
          };

          // Upsert to calorie using DynamicClientToServerUpsert
          await vxInstance.DynamicClientToServerUpsert("calorie", accessToken, refreshToken, jsonCalorie);

          let coinEarnMealToday = await getCoinById(
            `${user?.userEmail}-MEAL-${todayDate}`,
          );
          
          if (!coinEarnMealToday) {
            let toEarnArray = await ClaimCoins();
            if (toEarnArray.length) {
              setCoinEarnedAmount(
                toEarnArray.reduce(
                  (sum, transaction) => sum + transaction.coinsEarned,
                  0,
                ),
              );
              setCoinEarnedAnimation(true);

              let coinsTodayArray = await MyCoinHistory(
                user?.userEmail,
                getStartOfDayISOStringZ(),
                getEndOfDayISOStringZ(),
              );

              let coinToday = coinsTodayArray?.reduce(
                (sum, transaction) => sum + transaction.coinsEarned,
                0,
              );
              if (coinToday) {
                setCoinEarnedToday(coinToday);
              }
            }
          }
          setMealState((prevState) => ({
            ...prevState,
            mealSnappedId: null,
          }));
          navigate("/gallery");
        }
      }
    };
    asyncWrapper();
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <button onClick={handleBack} className="backButton"></button>
      {imageSrc && (
        <Cropper
          src={imageSrc}
          style={{ height: 400, width: "100%" }}
          aspectRatio={1}
          guides={false}
          ref={cropperRef}
          viewMode={1}
          dragMode="move"
          scalable={true}
          crop={handleCropTemp}
        />
      )}
      <div className="flex items-center justify-center mt-3">
        <>
          <button
            className="bg-blue-400 text-white rounded-full px-3 py-2"
            onClick={handleCrop}
          >
            <FontAwesomeIcon size="3x" icon={faCheck} />
          </button>
          <button
            className="bg-blue-400 text-white rounded-full px-4 py-2"
            onClick={handleBack}
          >
            <FontAwesomeIcon size="3x" icon={faTimes} />
          </button>
        </>
      </div>
    </div>
  );
};

export default MealCrop;
