import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
PouchDB.plugin(PouchDBFind);

const calorieDB = new PouchDB("calorieDB");

export const getCaloriesByUserEmail = async (userEmail) => {
  try {
    const result = await calorieDB.allDocs({ include_docs: true });
    const filteredDocs = result.rows
      .map(row => row.doc)
      .filter(doc => doc.userEmail === userEmail && doc.calorieDateTime);

    console.log("Calories retrieved for user email:", filteredDocs);
    return filteredDocs;
  } catch (error) {
    debugger;
    console.error("Error retrieving calories for user email:", error);
    return [];
  }
};

export const getCaloriesByUserEmailAndDateTimeString = async (
  userEmail,
  startDateTime,
  endDateTime,
  calorieType = null,
) => {
  try {
    const result = await calorieDB.allDocs({ include_docs: true });
    const filteredDocs = result.rows
      .map(row => row.doc)
      .filter(doc => 
        doc.userEmail === userEmail &&
        doc.calorieDateTime >= startDateTime &&
        doc.calorieDateTime <= endDateTime &&
        (!calorieType || doc.calorieType === calorieType)
      )
      .sort((a, b) => b.calorieDateTime.localeCompare(a.calorieDateTime));

    console.log("Calories retrieved for user within date range:", filteredDocs);
    return filteredDocs;
  } catch (error) {
    console.error("Error retrieving calories for user within date range:", error);
    return [];
  }
};

export const addCalorieItem = async (item) => {
  try {
    debugger;
    const existingItem = await calorieDB.get(item._id).catch((err) => {
      if (err.status === 404) {
        return null;
      }
      throw err;
    });
    if (existingItem) {
      item._rev = existingItem._rev;
    }
    await calorieDB.put(item);
  } catch (error) {
    if (error.name === "conflict") {
      try {
        const resolvedItem = await calorieDB.get(item._id);
        item._rev = resolvedItem._rev;
        await calorieDB.put(item);
      } catch (retryError) {
        console.log(retryError);
      }
    } else {
      console.log(error);
    }
  }
};

export const getCalorieById = async (id) => {
  try {
    const byId = await calorieDB.get(id);
    console.log("Calorie byId retrieved:", byId);
    return byId;
  } catch (error) {
    if (error.status === 404) {
      console.warn("Calorie byId not found:", id);
      return null;
    }
    console.log("Error retrieving calorie:", error);
    return null;
  }
};

export const deleteCalorieItem = async (id) => {
  try {
    const item = await calorieDB.get(id);
    const response = await calorieDB.remove(item);
    return response;
  } catch (error) {
    console.error("Error deleting calorie item:", error);
    throw error;
  }
};

export default calorieDB;
