import { atom } from 'recoil';

export const versionAtom = atom({
  key: 'versionAtom', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
});

// Function to set version in the atom
export const setVersion = (version) => {
  versionAtom.set(version);
};