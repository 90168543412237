import { v4 as uuidv4 } from 'uuid'; // Import UUID library
import MyEnv from './MyEnv'; // Import MyEnv

// Function to generate Google OAuth URL
export const getGoogleOAuthUrl = () => {
    const frontEndUri = process.env.REACT_APP_PUBLIC_URL;
    const frontEndState = uuidv4(); // Generate a unique state using UUID

    // Store the front-end state in localStorage
    localStorage.setItem('frontEndState', frontEndState);

    // Use MyEnv to construct the OAuth URL
    const oauthUrl = `${MyEnv.RBOLINGOOGLE}?front-end-uri=${encodeURIComponent(frontEndUri)}&front-end-state=${frontEndState}/#`;
    return oauthUrl; // Return the constructed URL
};

// ... existing code ...
