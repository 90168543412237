import React, { useState, useEffect } from "react";
import CurrencyInput from "react-currency-input-field";
import { useRecoilState } from "recoil";
import { questionAnswerState } from "../../../atom/survey/survey-questions-atoms";
import "./HeightPicker.css";

const HeightPicker = ({ height, setHeight, handleKeyPress }) => {
  const [heightAnswer, setHeightAnswer] = useRecoilState(
    questionAnswerState("700"),
  );
  const [inputClass, setInputClass] = useState("input-unfocused");

  useEffect(() => {
    if (heightAnswer?.surveyAnswerString) {
      setHeight(parseInt(heightAnswer.surveyAnswerString, 10));
    } else {
      setHeight(""); // Default height if not set
    }
  }, [heightAnswer, setHeight]);

  const handleHeightChange = (value) => {
    if (typeof value === "undefined" || value === null || value === "") {
      setHeight("");
      updateSurveyAnswer("");
      return;
    }

    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue) && parsedValue <= 300) {
      setHeight(parsedValue);
      updateSurveyAnswer(parsedValue.toString());
    }
  };

  const updateSurveyAnswer = (answerString) => {
    setHeightAnswer({
      surveyQuestionNumber: "700",
      surveyAnswerString: answerString,
      surveyAnswerList: [],
    });
  };

  return (
    <div className="flex justify-center items-center">
      <CurrencyInput
        className={`text-center ${inputClass}`}
        style={{
          fontSize: "3rem",
          width: "55vw",
          height: "4rem",
        }}
        value={height}
        decimalsLimit={0}
        onValueChange={handleHeightChange}
        allowNegativeValue={false}
        disableGroupSeparators={true}
        placeholder="0"
        onFocus={() => setInputClass("input-focused")}
        onBlur={() => setInputClass("input-unfocused")}
        onKeyPress={handleKeyPress}
      />
      <span className="ml-2" style={{ fontSize: "3rem" }}>
        cm
      </span>
    </div>
  );
};

export default HeightPicker;
