import React, { useMemo } from "react";
import Typewriter from "typewriter-effect";
import { useRecoilState } from "recoil";
import { currentQuestionState } from "../../../atom/survey/survey-atoms";
import { questionAnswerState } from "../../../atom/survey/survey-questions-atoms";
import { addSurvey } from "../../../store/pouch/survey-pouch";
import { userState } from "../../../atom/atoms";
import { addSurveyQuestion, iterateAllSurveyQuestionsByUserEmail } from "../../../store/pouch/survey-questions-pouch";
import useTokenCheckVx from "../../../hooks/useTokenCheckVx";

import vxInstance from "../../../api/VxApiService";
import { getUserObj } from "../../../store/pouch/user-pouch";

const LifestyleChanges = () => {
  const [user, setUser] = useRecoilState(userState);
  const [currentQuestion, setCurrentQuestion] =
    useRecoilState(currentQuestionState);
  const [lifestyleAnswer, setLifestyleAnswer] = useRecoilState(
    questionAnswerState("900"),
  );

  const { isApiConnected, isLoading } = useTokenCheckVx();

  const canSyncWithServer = useMemo(() => isApiConnected && !isLoading, [isApiConnected, isLoading]);

  const options = [
    "Consult doctor or dietician",
    "Exercise",
    "Follow Specific Diet/Fasting",
    "Blood Sugar Monitoring",
    "Surgery (Liposuction, Bariatric)",
    "Daily weighing",
    "Others",
  ];
  const optionDisplayMap = {
    "Consult doctor or dietician": "🩺Consult Dr/Dietician",
    Exercise: "🏃‍♀️Exercise⚽",
    "Follow Specific Diet/Fasting": "🥗Specific Diet/Fasting",
    "Blood Sugar Monitoring": "🩸Blood Sugar Monitoring",
    "Surgery (Liposuction, Bariatric)": "🔪Surgery (Lipo/Bariatric)",
    "Daily weighing": "🎯Daily weighing",
    Others: "📝Others",
  };

  const selectedOptions = lifestyleAnswer?.surveyAnswerList || [];
  const otherText = lifestyleAnswer?.surveyAnswerString || "";

  const handleOptionToggle = (option) => {
    const newSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];
    setLifestyleAnswer({
      surveyQuestionNumber: "900",
      surveyAnswerString: otherText,
      surveyAnswerList: newSelectedOptions,
    });
  };

  const handleTextChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^a-zA-Z0-9\s.,'"():-]/g, "");
    if (value.length > 1000) {
      value = value.substring(0, 1000);
    }
    setLifestyleAnswer({
      surveyQuestionNumber: "900",
      surveyAnswerString: value,
      surveyAnswerList: selectedOptions,
    });
  };

  const isNextDisabled = () => {
    return selectedOptions.includes("Others") && otherText.trim() === "";
  };

  const doneLifestyle = async () => {
    try {
      const answerJson = {
        _id: `${user?.userEmail}-surveyQNum-900`,
        surveyQuestionNumber: "900",
        surveyAnswerString: otherText,
        surveyAnswerList: selectedOptions,
        userEmail: user?.userEmail,
      };

      // Add survey question
      await addSurveyQuestion(answerJson);

      let userSurveyProgress = {
        currentQuestion: "1000",
        isComplete: false,
        completionDateTime: null,
        earnedCoinAmount: 0,
        earnedCoinDatetime: null,
      };

      await addSurvey({ _id: user?.userEmail, ...userSurveyProgress });

      if (canSyncWithServer) {
        try {
          let allQuestions = await iterateAllSurveyQuestionsByUserEmail(user?.userEmail);
          const userQuestions = allQuestions.filter(q => q.userEmail === user?.userEmail);
          vxInstance.surveyClientToServerUpsert(user?.accessToken, user?.refreshToken, {surveyData: userQuestions});
          // let userObj = await getUserObj(user?.userEmail);
          // vxInstance.userClientToServerUpsert(user?.accessToken, user?.refreshToken, userObj);

          console.log("Survey data and User data synchronized with server");
        } catch (apiError) {
          console.error("Failed to synchronize with server:", apiError);
          // We don't throw here to allow offline progression
        }
      } else {
        console.log("Offline mode or loading: Survey data saved locally");
      }

      setCurrentQuestion("1000");
    } catch (error) {
      console.error("Error saving survey question:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  return (
    <div className="flex flex-col items-center mt-4">
      <div className="text-center text-xl font-thin text-blue-600">
        {!isNextDisabled() && <>Last 2..</>}
        {isNextDisabled() && (
          <Typewriter
            options={{
              strings: ["Last 2.."],
              autoStart: true,
              loop: false,
              deleteSpeed: Infinity,
              cursor: "",
            }}
          />
        )}
      </div>
      <div className="text-center text-xl font-thin text-blue-600">
        What changes have you made
        <br /> to your lifestyle (multi choice) 🤾‍♀️ recently?
      </div>
      <div className="flex flex-wrap justify-center mt-4 gap-2">
        {options.map((option) => (
          <button
            key={option}
            onClick={() => handleOptionToggle(option)}
            className={`px-4 py-2 rounded ${
              selectedOptions.includes(option)
                ? "bg-blue-500 text-white"
                : "bg-gray-300 text-black"
            }`}
          >
            {optionDisplayMap[option]}
          </button>
        ))}
      </div>
      {selectedOptions.includes("Others") && (
        <textarea
          className="mt-4 p-2 border rounded w-3/4"
          placeholder="Please specify..."
          value={otherText}
          onChange={handleTextChange}
        />
      )}
      <div className="flex justify-center mt-4">
        <button
          type="button"
          onClick={() => setCurrentQuestion("800")}
          className="mt-2 px-4 py-2 bg-gray-500 text-white rounded mr-2"
        >
          Back
        </button>
        <button
          type="button"
          onClick={doneLifestyle}
          className={`mt-2 px-4 py-2 rounded ${
            isNextDisabled()
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-blue-500 text-white"
          }`}
          disabled={isNextDisabled()}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default LifestyleChanges;