import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userState } from '../../../atom/atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import useTokenCheckVx from '../../../hooks/useTokenCheckVx';
import { getUserObj } from '../../../store/pouch/user-pouch';
import { iterateAllSurveyQuestionsByUserEmail } from '../../../store/pouch/survey-questions-pouch';
import vxInstance from '../../../api/VxApiService';
import { ClaimCoins, ClaimCoinsOnline, getCoinAmountEarnedToday, MyCoinHistory } from '../../../util/CoinClaiming';
import { coinEarnedAmountState, coinEarnedAnimationState } from '../../../atom/coin/coin-atoms';
import { coinEarnedTodayState } from '../../../atom/coin/coin-today-atom';
import { getEndOfDayISOStringZ, getStartOfDayISOStringZ } from '../../../util/Util';

const DailyCheckin = () => {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const setCoinEarnedAmount = useSetRecoilState(coinEarnedAmountState);
  const setCoinEarnedAnimation = useSetRecoilState(coinEarnedAnimationState);
  const setCoinEarnedToday = useSetRecoilState(coinEarnedTodayState);
  const { isApiConnected, isLoading } = useTokenCheckVx();
  const [checkinFailed, setCheckinFailed] = useState(false);
  const [effectRan, setEffectRan] = useState(false);

  const performCheckin = useCallback(async () => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 120000); // 120 seconds

    try {
      let resp = await vxInstance.onlineCheckIn(user?.userEmail, user?.accessToken, user?.refreshToken, controller.signal);
      // Handle response
      //if http 200
      if (resp?.status === 200) {
        setCheckinFailed(false);
        let coinEarnedArray = await ClaimCoinsOnline();
        console.log("coinEarnedArray : ", coinEarnedArray);
        //reduce coinEarnedArray to coinEarnedAmount
        let coinEarnedAmount = coinEarnedArray.reduce((acc, curr) => acc + curr.coinsEarned, 0);
        if (coinEarnedAmount > 0) {
          setCoinEarnedAmount(coinEarnedAmount);
          setCoinEarnedAnimation(true);
        }
        let coinEarnedToday = await getCoinAmountEarnedToday(user?.userEmail);
        setCoinEarnedToday(coinEarnedToday);
      }
    } catch (apiError) {
      if (apiError.name === 'AbortError') {
        console.error("Check-in request timed out");
      } else {
        console.error("Failed to synchronize with server:", apiError);
      }
      setCheckinFailed(true);
    } finally {
      clearTimeout(timeoutId);
    }
  }, [user]);

  useEffect(() => {
    if (!effectRan) {
      performCheckin();
      setEffectRan(true);
    }
  }, [effectRan, performCheckin]);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="p-4">
      <button onClick={handleBack} className="mb-4">
        <FontAwesomeIcon icon={faChevronLeft} /> Back
      </button>

      <h1 className="text-2xl font-bold mb-4">Daily Check-in</h1>

      {isLoading && <p>Loading...</p>}

      {!isLoading && !isApiConnected && (
        <p className="text-red-500">Please connect to Internet</p>
      )}

      {isApiConnected && (
        <div>
          <p>Token Status: Valid</p>
          {/* Add more UI elements based on the token status */}
        </div>
      )}

      {checkinFailed && (
        <div className="mt-4 text-red-500">
          Unable to checkin now
          <button
            type="button"
            onClick={performCheckin}
            className="mt-2 px-4 py-2 text-white rounded-md bg-blue-400 shadow-md shadow-blue-800/35"
          >
            Retry Checkin
          </button>
        </div>
      )}

      {/* Add your daily check-in form or other components here */}
    </div>
  );
};

export default DailyCheckin;
