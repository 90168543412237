import React from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom"; // Adjust the path as needed
import {
  coinEarnedTodayState,
  coinTotalAmountState,
  isDeleteCoinAtom,
} from "../../../atom/coin/coin-today-atom";
import {
  completedStartScreenState,
  stateOfIntroductionState,
  userState,
} from "../../../atom/atoms";
import {
  currentQuestionState,
  surveyCompletionState,
} from "../../../atom/survey/survey-atoms";
import { surveyDataState } from "../../../atom/survey/survey-questions-atoms";
import { weightAtom } from "../../../atom/fatloss/weight-atom";
import { lifestyleAtom } from "../../../atom/lifestyle/lifestyle-atom";
import { habitAtom } from "../../../atom/habit/habit-atom";
import { getUserObj, deleteUserObj } from '../../../store/pouch/user-pouch'; // Import the necessary functions
import vxOAuthInstance from "../../../api/VxOAuthService";

const Logout = () => {
  const setCoinEarnedToday = useSetRecoilState(coinEarnedTodayState);
  const setCoinTotalAmount = useSetRecoilState(coinTotalAmountState);
  const setCompletedStartScreen = useSetRecoilState(completedStartScreenState);
  const setStateOfIntroduction = useSetRecoilState(stateOfIntroductionState);
  const setCurrentQuestionState = useSetRecoilState(currentQuestionState);
  const setSurveyCompletionState = useSetRecoilState(surveyCompletionState);
  const setSurveyDataState = useSetRecoilState(surveyDataState);
  const setWeightState = useSetRecoilState(weightAtom);
  const setLifestyleState = useSetRecoilState(lifestyleAtom);
  const setHabitState = useSetRecoilState(habitAtom);
  const setUser = useSetRecoilState(userState);
  const setIsDeleteTodayCoin = useSetRecoilState(isDeleteCoinAtom);
  const navigate = useNavigate();

  React.useEffect(() => {
    const handleLogout = async () => {
      //before clear, call google logout 

      // Retrieve the active user object
      const usrObj = await getUserObj("active");
      debugger;
      if (usrObj) {
        // Remove the active user from PouchDB
        vxOAuthInstance.logoutGoogle(usrObj?.accessToken);
        await deleteUserObj(usrObj._id);

      }

      
      setUser(null);
      setCompletedStartScreen(false);
      setStateOfIntroduction(false);
      setCoinEarnedToday(0);
      setCoinTotalAmount(0);
      setCurrentQuestionState("0");
      setSurveyCompletionState(false);
      setSurveyDataState([]);
      setWeightState({
        versionId: 1,
        lastSynchronized: null,
        userEmail: "",
        weightHistory: [],
        weightTxDate: "",
      });
      setLifestyleState({
        userEmail: null,
        lifestyleQuestionDate: null,
        lifestyleResponses: [],
      });
      setHabitState({
        userEmail: null,
        habitQuestionDate: null,
        habitResponses: [],
        currentHabitQuestionNumber: 0,
      });

      setIsDeleteTodayCoin(true);
      // clearSurveyAtom({ set: reset });
      // clearSurveyQuestionsAtom({ set: reset });
      // clearDishHistoryAtom({ set: reset });
      // clearLifestyleAtom({ set: reset });
      // clearHabitAtom({ set: reset });
      // clearWeightAtom({ set: reset });
      // clearExerciseAtom({ set: reset });
      // clearDailyAtom({ set: reset });

      navigate("/");
    };
    handleLogout();
  }, [
    navigate,
    setUser,
    setCompletedStartScreen,
    setStateOfIntroduction,
    setCoinEarnedToday,
    setCoinTotalAmount,
    setCurrentQuestionState,
    setSurveyCompletionState,
    setSurveyDataState,
    setWeightState,
    setLifestyleState,
    setHabitState,
    setIsDeleteTodayCoin,
  ]);

  return (
    <div>
      <p>Logging out...</p>
    </div>
  );
};

export default Logout;
