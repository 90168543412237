// src/component/mission/EarnedCoin.js
import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  coinEarnedAmountState,
  coinEarnedAnimationState,
} from "../../atom/coin/coin-atoms";
import "./EarnedCoin.css";

const EarnedCoin = () => {
  const [visible, setVisible] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [moveToCorner, setMoveToCorner] = useState(false);
  const [animationSpeed, setAnimationSpeed] = useState(1); // Base speed multiplier
  const earnedAmount = useRecoilValue(coinEarnedAmountState);
  const setEarnedAmount = useSetRecoilState(coinEarnedAmountState);
  const setEarnedAnimation = useSetRecoilState(coinEarnedAnimationState);

  useEffect(() => {
    if (earnedAmount > 0) {
      setVisible(true);
      setEarnedAnimation(true);
      const rotateTimer = setTimeout(() => {
        setToastVisible(true);
      }, 2000);

      const moveTimer = setTimeout(() => {
        setMoveToCorner(true);
      }, 4000 / animationSpeed); // Adjusted speed based on clicks

      const hideTimer = setTimeout(() => {
        setVisible(false);
        setToastVisible(false);
        setMoveToCorner(false);
        setEarnedAmount(0);
        setEarnedAnimation(false);
      }, 6000 / animationSpeed); // Adjusted speed based on clicks

      return () => {
        setVisible(false);
        clearTimeout(rotateTimer);
        clearTimeout(moveTimer);
        clearTimeout(hideTimer);
      };
    }
  }, [earnedAmount, animationSpeed, setEarnedAmount, setEarnedAnimation]);

  // Click handler to increase speed
  const handleClick = () => {
    setAnimationSpeed((prevSpeed) => Math.min(prevSpeed + 1.5, 10)); // Cap max speed
  };

  if (!visible) return null;

  return (
    <div className="earned-coin-overlay" onClick={handleClick}>
      <div className="coins-container">
        {[...Array(earnedAmount)].map((_, index) => (
          <div
            key={index}
            className={`coin-container ${moveToCorner ? "move-to-corner" : ""}`}
            style={{
              transitionDelay: `${index * 0.1}s`,
              transform: `translate(${Math.random() * 100 - 50}px, ${Math.random() * 100 - 50}px)`,
              transitionDuration: `${0.5 / animationSpeed}s`, // Adjust duration based on speed
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/daily2/00-coin-2.svg`}
              alt="Earned Coin"
              className={`coin ${moveToCorner ? "shrink" : ""}`}
            />
            <div className="sparkle sparkle1"></div>
            <div className="sparkle sparkle2"></div>
            <div className="sparkle sparkle3"></div>
            <div className="sparkle sparkle4"></div>
          </div>
        ))}
      </div>
      {toastVisible && (
        <div className="toast-message">
          You earned <br />
          {earnedAmount} coin{earnedAmount === 1 ? "" : "s"}
        </div>
      )}
    </div>
  );
};

export default EarnedCoin;
