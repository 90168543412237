// src/component/ideal/IdealWeight.js
import React, { useEffect } from 'react';

const CurrentWeight = ({ weight, need }) => {
    return (
        <div className="bg-blue-400 text-white p-4 rounded-lg shadow-md shadow-blue-800/35">
            <h2 className="text-lg font-semibold mb-2">Current Weight
                <span>
                    {need !== 0 && (
                        <span 
                        className={`${need > 0 ? 'text-green-200' : 'text-red-500'}`}
                        style={{
                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)'
                        }}>
                        <br/>Need {(need > 0 ? "+" : "")}{need} kg
                        </span>
                    )}
                </span>
            </h2>
            <p className="text-xl">{weight} kg</p>
        </div>
    );
};

export default CurrentWeight;
