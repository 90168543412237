import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
PouchDB.plugin(PouchDBFind);

export const mealDB = new PouchDB("mealDB");

export const findMealByUserAndId = async (userEmail, mealId) => {
  try {
    const result = await mealDB.allDocs({ include_docs: true });

    const filteredDocs = result.rows
      .map(row => row.doc)
      .filter(doc => doc.userEmail === userEmail && doc.mealId === mealId);

    if (filteredDocs.length > 0) {
      console.log("Meal record found:", filteredDocs[0]);
      return filteredDocs[0];
    } else {
      console.log("No meal record found for the given criteria.");
      return null;
    }
  } catch (error) {
    console.error("Error finding meal record:", error);
    return null;
  }
};

export const getMealsByUserEmailAndEpoch = async (
  userEmail,
  startDateTime = null,
  endDateTime = null,
) => {
  try {
    const result = await mealDB.allDocs({ include_docs: true });
    const startEpoch = startDateTime ? new Date(startDateTime).getTime() : null;
    const endEpoch = endDateTime ? new Date(endDateTime).getTime() : null;

    const filteredDocs = result.rows
      .map(row => row.doc)
      .filter(doc => 
        doc.userEmail === userEmail &&
        (startEpoch === null || doc.mealEpoch >= startEpoch) &&
        (endEpoch === null || doc.mealEpoch <= endEpoch)
      )
      .sort((a, b) => b.mealEpoch - a.mealEpoch);

    return filteredDocs;
  } catch (err) {
    console.error("Error retrieving meals by user email and epoch:", err);
    return [];
  }
};

export const addMealItem = async (item) => {
  try {
    const existingItem = await mealDB.get(item._id).catch((err) => {
      if (err.status === 404) {
        return null;
      }
      throw err;
    });
    if (existingItem) {
      item._rev = existingItem._rev;
    }
    await mealDB.put(item);
  } catch (error) {
    if (error.name === "conflict") {
      try {
        const resolvedItem = await mealDB.get(item._id);
        item._rev = resolvedItem._rev;
        await mealDB.put(item);
      } catch (retryError) {
        console.log(retryError);
      }
    } else {
      console.log(error);
    }
  }
};
