// src/store/pouch/otp-pouch.js
import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
PouchDB.plugin(PouchDBFind);

export const otpDB = new PouchDB("otpDB");

export const addOtp = async (otp) => {
    try {
      const response = await otpDB.put(otp);
      return response;
    } catch (error) {
      if (error.name === "conflict") {
        try {
          const existingOtp = await otpDB.get(otp._id);
          otp._rev = existingOtp._rev;
  
          const response = await otpDB.put(otp);
          return response;
        } catch (retryError) {
          throw retryError;
        }
      } else {
        throw error;
      }
    }
};

export const matchOtpByUserEmail = async (
    userEmail,
    otpDe,
  ) => {
    try {
      const allDocs = await otpDB.allDocs({
        include_docs: true,
      });
  
      const filteredDocs = allDocs.rows.filter((row) => {
        const doc = row.doc;
        const docDate = new Date(doc.otpDate);
        return (
          doc.userEmail === userEmail &&
          !!doc.isValid &&
          doc.otpDe === otpDe
        );
      });
  
      console.log(
        "Otp retrieved for user within date range:",
        filteredDocs.map(row => row.doc),
      );
      return filteredDocs.map(row => row.doc);
    } catch (error) {
      console.error(
        "Error retrieving lifestyle for user within date range:",
        error,
      );
      return [];
    }
};


export const checkExistingValidOtpByUserEmail = async (
  userEmail,
) => {
  try {
    const allDocs = await otpDB.allDocs({
      include_docs: true,
    });

    const filteredDocs = allDocs.rows.filter((row) => {
      const doc = row.doc;
      return (
        doc.userEmail === userEmail && doc.isValid
      );
    });

    console.log(
      "Otp retrieved for user within date range:",
      filteredDocs.map(row => row.doc),
    );
    return filteredDocs.map(row => row.doc);
  } catch (error) {
    console.error(
      "Error retrieving lifestyle for user within date range:",
      error,
    );
    return [];
  }
};

//setOtpIsValidFalseByUserEmail
export const setOtpIsValidFalseByUserEmail = async (userEmail) => {
    try {
      // Get all documents
      const allDocs = await otpDB.allDocs({
        include_docs: true,
      });
  
      // Filter and update documents
      const updatePromises = allDocs.rows
        .filter(row => row.doc.userEmail === userEmail && row.doc.isValid)
        .map(row => {
          const updatedDoc = { ...row.doc, isValid: false };
          return otpDB.put(updatedDoc);
        });
  
      // Wait for all updates to complete
      await Promise.all(updatePromises);
  
      console.log(`OTP documents updated for user: ${userEmail}`);
      return true;
    } catch (error) {
      console.error(`Error updating OTP documents for user ${userEmail}:`, error);
      return false;
    }
};
  
  
