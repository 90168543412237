import {
  faArrowLeft,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";
import { getUserObj } from "../../../store/pouch/user-pouch";
import {
  addLifestyleQuestion,
  getLifestyleQuestionsByIds,
  getLifestyleQuestionsByUserEmailAndDateTimeRange,
} from "../../../store/pouch/lifestyle-pouch";
import {
  convertYYYYMMDDToDateObj,
  convertYYYYMMDDToDateObjEOD,
  getEndOfDayISOStringZ,
  getLocalDateOnly,
  getStartOfDayISOStringZ,
} from "../../../util/Util";
import { ClaimCoins } from "../../../util/CoinClaiming";
import {
  coinEarnedAmountState,
  coinEarnedAnimationState,
} from "../../../atom/coin/coin-atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { coinEarnedTodayState } from "../../../atom/coin/coin-today-atom";
import {
  addLifestyleResponse,
  lifestyleAtom,
  updateLifestyleAtomSelector,
} from "../../../atom/lifestyle/lifestyle-atom";
import { userState } from "../../../atom/atoms";
import { getCoinsByUserEmailAndDateTimeString } from "../../../store/pouch/coin-pouch";
import vxInstance from "../../../api/VxApiService"; // Import vxInstance

const LifestyleQuestion = ({
  nickname,
  currentLifestyleQuestion,
  setCurrentLifestyleQuestion,
  upsertLifestyleAnswer,
  lifestyleResponse,
  setLifestyleResponse,
  questions,
}) => {
  const user = useRecoilValue(userState);
  const [selectedLifestyleAnswer, setSelectedLifestyleAnswer] = useState("no");
  const [clickedButton, setClickedButton] = useState(null);
  const setCoinEarnedAmount = useSetRecoilState(coinEarnedAmountState);
  const setCoinEarnedAnimation = useSetRecoilState(coinEarnedAnimationState);
  const setCoinEarnedToday = useSetRecoilState(coinEarnedTodayState);

  const setLifestyleAtom = useSetRecoilState(updateLifestyleAtomSelector);

  const updateLifestyleAtom = (userEmail, lifestyleQuestionDate) => {
    setLifestyleAtom({
      userEmail,
      lifestyleQuestionDate,
    });
  };
  const navigate = useNavigate();

  const handleAnswer = (answer) => {
    const asyncWrapper = async () => {
      setSelectedLifestyleAnswer(answer);
      setClickedButton(answer);
      upsertLifestyleAnswer(
        questions[currentLifestyleQuestion - 1].num,
        answer,
        [],
        setLifestyleResponse,
      );
      const todayDate = getLocalDateOnly();
      const questionNum = questions[currentLifestyleQuestion - 1].num;
      let userDailyLifestylePK = `${user?.userEmail}-lifestyle-${todayDate}-${questionNum}`;
      let jsonLifestyle = {
        _id: userDailyLifestylePK,
        userEmail: user?.userEmail,
        lifestyleQuestionDate: todayDate,
        lifestyleQuestionNum: questionNum,
        lifestyleQuestionResponse: answer,
      };
      await addLifestyleQuestion(jsonLifestyle);

      if (currentLifestyleQuestion < questions.length) {
        setCurrentLifestyleQuestion(currentLifestyleQuestion + 1);
      }

      if (currentLifestyleQuestion === questions.length) {
        // Perform earn coin
        debugger;
        let coinEarnedLifestyleArray = await ClaimCoins();
        let toEarn = coinEarnedLifestyleArray?.reduce(
          (sum, transaction) => sum + transaction.coinsEarned,
          0,
        );
        updateLifestyleAtom(user?.userEmail, todayDate);
        addLifestyleResponse(setLifestyleAtom, jsonLifestyle);
        setCoinEarnedAmount(toEarn);
        if (toEarn) {
          setCoinEarnedAnimation(true);
          let todayCoinArray = await getCoinsByUserEmailAndDateTimeString(
            user?.userEmail,
            getStartOfDayISOStringZ(),
            getEndOfDayISOStringZ(),
          );
          let todayCoin = todayCoinArray?.reduce(
            (sum, transaction) => sum + transaction.coinsEarned,
            0,
          );
          setCoinEarnedToday(todayCoin);
        }

        // Retrieve all lifestyle data for the user
        const lifestyleDataArray = await getLifestyleQuestionsByUserEmailAndDateTimeRange(user?.userEmail, null, null);

        // Call lifeStyleClientToServerUpsert and await its completion
        const { accessToken, refreshToken } = user;
        vxInstance.lifeStyleClientToServerUpsert(accessToken, refreshToken, { lifestyleData: lifestyleDataArray });

        navigate("/fat-loss");
      }
    };
    asyncWrapper();
  };

  const handleBack = () => {
    if (currentLifestyleQuestion === 1) {
      navigate("/daily");
    } else {
      setCurrentLifestyleQuestion(currentLifestyleQuestion - 1);
    }
  };

  useEffect(() => {
    if (clickedButton) {
      const timer = setTimeout(() => {
        setClickedButton(null);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [clickedButton]);

  const currentQuestion = questions[currentLifestyleQuestion - 1];

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col items-center">
        <img
          src={`${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`}
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover mb-4"
        />
        <div className="text-center mb-4 text-xl text-blue-600">
          {currentQuestion?.title}
        </div>
        <div className="text-center mb-4 text-xl font-thin text-blue-600">
          <Typewriter
            options={{
              strings: [currentQuestion?.question],
              autoStart: true,
              loop: false,
              deleteSpeed: Infinity,
              delay: 25,
              cursor: "",
            }}
          />
        </div>
        <div className="flex space-x-4 mb-4">
          <button
            type="button"
            onClick={() => handleAnswer("yes")}
            className={`px-4 py-2 rounded border-2 transition-colors duration-300 ${
              clickedButton === "yes"
                ? "border-blue-500 text-blue-500 bg-white"
                : "bg-blue-500 text-white"
            }`}
          >
            <div className="flex items-center">
              <FontAwesomeIcon icon={faCheck} size="2x" />
              <span className="ml-2">Yes</span>
            </div>
          </button>
          <button
            type="button"
            onClick={() => handleAnswer("no")}
            className={`px-4 py-2 rounded border-2 transition-colors duration-300 ${
              clickedButton === "no"
                ? "border-blue-500 text-blue-500 bg-white"
                : "bg-blue-500 text-white"
            }`}
          >
            <div className="flex items-center">
              <FontAwesomeIcon icon={faTimes} size="2x" />
              <span className="ml-2">No</span>
            </div>
          </button>
        </div>
        <button
          type="button"
          onClick={handleBack}
          className="px-4 py-2 rounded border-2 border-gray-300 text-gray-600 hover:bg-gray-100 transition-colors duration-300"
        >
          <div className="flex items-center ">
            <FontAwesomeIcon icon={faArrowLeft} size="1x" />
            <span className="ml-2">Back</span>
          </div>
        </button>
      </div>
      <div className="bg-white p-4 mt-32">
        <p className="text-blue-500 text-center text-lg font-semibold ">
          Question {currentLifestyleQuestion} of {questions.length}
        </p>
      </div>
    </div>
  );
};

export default LifestyleQuestion;
