import axios from 'axios';

const VxVersionService = {
  fetchVersion: async () => {
    try {
      const response = await axios.get(`${process.env.PUBLIC_URL}/version.txt`);
      return response.data.trim(); // Return the version string
    } catch (error) {
      console.error("Error fetching version:", error);
      return null; // Handle error appropriately
    }
  },
};

export default VxVersionService;