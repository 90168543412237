import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
PouchDB.plugin(PouchDBFind);

let weightDBInstance;

export const getWeightDB = () => {
  if(!weightDBInstance) {
    weightDBInstance = new PouchDB("weightDB");
  }
  return weightDBInstance;
};

export const getWeightByUserEmailAndDateTimeString = async (
  userEmail,
  startDateTime = null,
  endDateTime = null,
) => {
  try {
    //need to retrieve weight by userEmail with all docs and iterate filter
    //also cater for datetime range default to all time if not provided
    const allDocs = await getWeightDB().allDocs({
      include_docs: true,
    });
    const filteredDocs = allDocs.rows.filter((row) => {
      return (
        row.doc.userEmail === userEmail &&
        (startDateTime === null || new Date(row.doc.weightTxDateTime) >= new Date(startDateTime)) &&
        (endDateTime === null || new Date(row.doc.weightTxDateTime) <= new Date(endDateTime))
      );
    });
    return filteredDocs;
  } catch (error) {
    console.error(
      "Error retrieving weights for user within date range:",
      error,
    );
    return [];
  }
};

export const addWeightItem = async (item) => {
  try {
    const itemWithStringDates = {
      ...item,
      weightTxDateTime:
        item.weightTxDateTime instanceof Date
          ? item.weightTxDateTime.toISOString()
          : item.weightTxDateTime,
    };
    item = itemWithStringDates;
    const existingItem = await getWeightDB().get(item._id).catch((err) => {
      if (err.status === 404) {
        return null;
      }
      throw err;
    });
    if (existingItem) {
      item._rev = existingItem._rev;
    }
    await getWeightDB().put(item);
  } catch (error) {
    if (error.name === "conflict") {
      try {
        const resolvedItem = await getWeightDB().get(item._id);
        item._rev = resolvedItem._rev;
        await getWeightDB().put(item);
      } catch (retryError) {
        console.log(retryError);
      }
    } else {
      console.log(error);
    }
  }
};

export const getWeightById = async (id) => {
  try {
    const byId = await getWeightDB().get(id);
    console.log("Weight byId retrieved:", byId);
    return byId;
  } catch (error) {
    if (error.status === 404) {
      console.warn("Weight byId not found:", id);
      return null;
    }
    console.log("Error retrieving weight:", error);
    return null;
  }
};

export const deleteWeightItem = async (id) => {
  try {
    const item = await getWeightDB().get(id);
    const response = await getWeightDB().remove(item);
    return response;
  } catch (error) {
    console.error("Error deleting weight item:", error);
    throw error;
  }
};

