import React, { useState, useRef, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { mealAtom } from "../../atom/meal/meal-atom";
import { addMealItem, mealDB, getMealsByUserEmailAndEpoch } from "../../store/pouch/meal-pouch";
import { getCaloriesByUserEmailAndDateTimeString } from "../../store/pouch/calorie-pouch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faImage, faCamera, faCameraRotate, faChevronLeft, faCalendar, faFolder, faSun, faClock, faTv, faUtensils, faMoon } from "@fortawesome/free-solid-svg-icons";
import { getCoinById } from "../../store/pouch/coin-pouch";
import { userState } from "../../atom/atoms";
import {
  getEndOfDayISOStringZ,
  getLocalDateOnly,
  getStartOfDayISOStringZ,
} from "../../util/Util";
import { ClaimCoins, MyCoinHistory } from "../../util/CoinClaiming";
import {
  coinEarnedAmountState,
  coinEarnedAnimationState,
} from "../../atom/coin/coin-atoms";
import { coinEarnedTodayState } from "../../atom/coin/coin-today-atom";
import vxInstance from "../../api/VxApiService";
import useTokenCheckVx from "../../hooks/useTokenCheckVx";
import { nanoid } from "nanoid";
import Typewriter from "typewriter-effect";
import "./MealSnap.css";

const MealSnap = () => {
  const { isApiConnected, isLoading } = useTokenCheckVx();
  const setMealState = useSetRecoilState(mealAtom);
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const [mealType, setMealType] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShowCamera, setIsShowCamera] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isBlurry, setIsBlurry] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [addedMeals, setAddedMeals] = useState([]);
  const [showToastModal, setShowToastModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isClosing, setIsClosing] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const options = [
    { label: "Breakfast", icon: faSun },
    { label: "Lunch", icon: faUtensils },
    { label: "Dinner", icon: faClock },
    { label: "Snack", icon: faTv },
    { label: "Supper", icon: faMoon },
  ];

  useEffect(() => {
    const fetchAddedMeals = async () => {
      const startOfDay = getStartOfDayISOStringZ();
      const endOfDay = getEndOfDayISOStringZ();
      const meals = await getMealsByUserEmailAndEpoch(user?.userEmail, startOfDay, endOfDay);
      setAddedMeals(meals.map(meal => meal.mealType));
      
      // Set default meal type to the first meal type that hasn't been added today
      const defaultMealType = options.find(option => !meals.some(meal => meal.mealType === option.label));
      setMealType(defaultMealType ? defaultMealType.label : null);
    };

    fetchAddedMeals();
  }, [user?.userEmail]);

  const handleBack = () => {
    navigate("/daily");
  };

  const handleGallery = () => {
    navigate("/gallery");
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: { exact: "environment" },
        },
      });
      videoRef.current.srcObject = stream;
      setIsShowCamera(true);
    } catch (err) {
      try {
        const fallbackStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        videoRef.current.srcObject = fallbackStream;
        setIsShowCamera(true);
      } catch (fallbackErr) {
        console.error("Error accessing any camera:", fallbackErr);
        setIsShowCamera(false);
      }
    }
  };

  const toggleCamera = async () => {
    const currentFacingMode = videoRef.current.srcObject
      .getVideoTracks()[0]
      .getSettings().facingMode;
    const newFacingMode = currentFacingMode === "user" ? "environment" : "user";

    const constraints = {
      video: { facingMode: { exact: newFacingMode } },
    };

    try {
      const newStream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = newStream;
    } catch (err) {
      console.error("Error switching camera:", err);
    }
  };

  const stopCamera = () => {
    const stream = videoRef.current.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
    setIsShowCamera(false);
  };

  const takePicture = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, 128, 128);
    canvas.toBlob(async (blob) => {
      const mealId = nanoid(10);
      const reader = new FileReader();

      reader.readAsDataURL(blob);
      reader.onloadend = async () => {
        const base64Data = reader.result;
        try {
          let dt = new Date();
          let newMealSnappedId = `${user?.userEmail}-meal-${dt.toISOString()}`;
          await addMealItem({
            _id: newMealSnappedId,
            mealId,
            userEmail: user.email,
            mealType,
            mealDate: getLocalDateOnly(),
            mealDateTime: dt.toISOString(),
            mealEpoch: dt.getTime(),
            mealAttachmentB64Array: [base64Data],
            mealIsDeleted: false,
          });
          setMealState((prevState) => ({
            ...prevState,
            mealSnappedId: newMealSnappedId,
          }));
          navigate("/mealcrop");
        } catch (error) {
          console.error("Error saving meal:", error);
        }
      };
      stopCamera();
    }, "image/jpeg");
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const validFormats = ["image/jpeg", "image/jpg", "image/jfif", "image/gif", "image/png", "image/tiff"];
      if (!validFormats.includes(file.type)) {
        alert("Invalid file format. Please select a valid image file.");
        return;
      }
      if (file.size > 15 * 1024 * 1024) {
        alert("File size exceeds the limit of 15MB.");
        return;
      }
      const reader = new FileReader();
      reader.onload = async (e) => {
        const imageDataUrl = e.target.result;
        // const blurry = await isImageBlurry(imageDataUrl);
        // setIsBlurry(blurry);

        setSelectedImage(imageDataUrl);
        setErrorMessage(""); // Clear error message when a file is selected
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadMeal = () => {
    setIsUploadModalOpen(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
    setSelectedImage(null);
    setIsBlurry(false);
  };

  const handleAnalyze = () => {
    if (!selectedImage) {
      setErrorMessage("Please upload your meal photo first");
      return;
    }
    const asyncWrapper = async () => {
      try {
        let dt = new Date();
        let newMealSnappedId = `${user?.userEmail}-meal-${dt.toISOString()}`;
        await addMealItem({
          _id: newMealSnappedId,
          mealId: nanoid(10),
          userEmail: user.email,
          mealType,
          mealDate: getLocalDateOnly(),
          mealDateTime: dt.toISOString(),
          mealEpoch: dt.getTime(),
          mealAttachmentB64Array: [selectedImage],
          mealIsDeleted: false,
        });
        setMealState((prevState) => ({
          ...prevState,
          mealSnappedId: newMealSnappedId,
        }));
        navigate("/mealcrop");
      } catch (error) {
        console.error("Error saving meal:", error);
      }
    };
    asyncWrapper();
  };

  return (
    <>
      <div className="w-full flex justify-between px-4 mb-4">
        <>
          <button
            onClick={handleBack}
            className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
          >
            <FontAwesomeIcon icon={faChevronLeft} size="lg" /> Back
          </button>
          <button
            onClick={handleGallery}
            className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
          >
            <FontAwesomeIcon icon={faCalendar} size="lg" /> Meal History
          </button>
        </>
      </div>

      <div className="flex flex-col justify-center items-center mt-4">
        <img
          src={`${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`}
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover"
        />
        <div className="text-center text-xl text-blue-600 ml-2">
          <div>Bon Apetit!</div>
        </div>
        <div className="flex justify-center mt-4">
          <div className="text-center text-xl font-thin text-blue-600">
            <Typewriter
              options={{
                strings: [`Snap top view of a ready meal.`],
                autoStart: true,
                loop: false,
                deleteSpeed: Infinity,
                cursor: "",
              }}
            />
          </div>
        </div>
      </div>

      {!isApiConnected && !isLoading && (
        <div className="text-center text-blue-400 shadow-md shadow-blue-800/35 mt-4">
          Please connect to internet to upload your meal.
        </div>
      )}

      {addedMeals.length < options.length ? (
        <>
          <div className="flex flex-col justify-center items-center mt-4">
            <button
              className="bg-blue-400 text-white px-4 py-2 rounded shadow-md shadow-blue-800/35"
              onClick={() => setIsModalOpen(true)}
            >
              Select Meal <b>({mealType})</b>
            </button>
          </div>

          <div className="flex flex-col justify-center items-center mt-4">
            <div className="flex items-center mt-4">
              <div
                className="camera-container bg-blue-400 w-32 h-48 rounded-lg border-2 border-gray-300 flex items-center justify-center cursor-pointer"
                onClick={startCamera}
              >
                <button onClick={startCamera}>
                  <div className="camera-placeholder">
                    {isShowCamera ? null : (
                      <>
                        <div className="shadow-lg shadow-gray-500">
                          <img
                            src={`${process.env.PUBLIC_URL}/daily2/10-meal-snap.jpeg`}
                            alt="Mealsnap Ready"
                            className=""
                          />
                        </div>
                        <div className="mt-2 mb-2 px-3 text-white font-medium">
                          Click here when ready
                        </div>
                      </>
                    )}
                  </div>
                </button>
              </div>
            </div>
            {/* add Upload Meal button, below */}
            <div className="flex flex-col justify-center items-center mt-4">
              <button
                className="bg-blue-400 text-white px-4 py-2 rounded shadow-md shadow-blue-800/35 flex items-center"
                onClick={handleUploadMeal}
              >
                <FontAwesomeIcon icon={faImage} className="ml-5 mr-2" />
                <span className="flex-1 text-center font-medium ">Upload Meal</span>
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="mt-16 text-blue-400 shadow">
          You have eaten all meals, please snap meals tomorrow.
        </div>
      )}

      <div className="flex flex-col justify-center items-center mt-4">
        <video
          ref={videoRef}
          style={{ display: isShowCamera ? "block" : "none" }}
          autoPlay
        />
        <canvas ref={canvasRef} style={{ display: "none" }} className="" />
        {isShowCamera && (
          <>
            <div className="flex  justify-center mt-4">
              <div className="mt-1 flex items-center justify-center bg-blue-400 rounded-full w-28 h-28 shadow-md shadow-blue-800/35">
                <button
                  className="flex items-center justify-center w-full h-full text-white"
                  onClick={takePicture}
                  disabled={!videoRef.current?.srcObject}
                >
                  <FontAwesomeIcon icon={faCamera} size="3x" />
                </button>
              </div>
            </div>
            <div className="flex  justify-center mt-4">
              <div className="mt-1 flex items-center justify-center bg-blue-400 rounded-full w-16 h-16 shadow-md shadow-blue-800/35">
                <button
                  className="flex items-center justify-center w-full h-full text-white"
                  onClick={toggleCamera}
                  disabled={!videoRef.current?.srcObject}
                >
                  <FontAwesomeIcon icon={faCameraRotate} size="2x" />
                </button>
              </div>
              <div className="mt-1 flex items-center justify-center bg-blue-400 rounded-full w-16 h-16 shadow-md shadow-blue-800/35">
                <button
                  className="flex items-center justify-center w-full h-full text-red-600"
                  onClick={stopCamera}
                  disabled={!videoRef.current?.srcObject}
                >
                  <FontAwesomeIcon icon={faTimes} size="3x" />
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      {isUploadModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white py-4 px-20 rounded">
            <div className="flex justify-between items-center mb-4">
              <div></div>
              <div className="text-blue-400 font-medium">Upload Meal</div>
              <button onClick={handleCloseUploadModal}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="flex justify-center mt-2">
              <input
                type="file"
                accept=".jpg,.jpeg,.jfif,.gif,.png,.tiff"
                onChange={handleFileChange}
                className="file-input-10"
                id="file-input"
              />
              <label htmlFor="file-input" 
                className={`bg-blue-500 rounded-md shadow-md shadow-blue-800/35 text-white text-center px-5 ${!selectedImage ? 'py-5' : ''}`}>
                <FontAwesomeIcon icon={faFolder} className="mr-2" />
                Choose File
              </label>
            </div>
            {errorMessage && (
              <div className="error-message">
                {errorMessage}
              </div>
            )}
            {selectedImage && (
              <div className="flex justify-center mb-4">
                <img src={selectedImage} alt="Selected" className="max-w-full h-auto" style={{ maxHeight: '60vh' }} />
              </div>
            )}
            {isBlurry && <p>The image is blurry.</p>}
            <div className="flex justify-center mt-4">
              <button
                className="btn-analyze bg-blue-400 text-white px-4 py-2 rounded shadow-md shadow-blue-800/35"
                onClick={handleAnalyze}
              >
                Analyze
              </button>
              <button
                className="btn-cancel ml-2 bg-gray-400 text-white px-4 py-2 rounded shadow-md shadow-blue-800/35"
                onClick={handleCloseUploadModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white py-4 px-20 rounded">
            {options.map((option) => {
              const isAdded = addedMeals.includes(option.label);
              return (
                <React.Fragment key={option.label}>
                  <button
                    className={`mb-3 block w-full rounded-md font-medium text-left shadow-lg shadow-blue-800/35 py-2 px-4 ${
                      isAdded
                        ? "border border-blue-400 bg-white text-blue-400"
                        : "bg-blue-400 text-white"
                    }`}
                    onClick={() => {
                      if (isAdded) {
                        setModalMessage(`You have already taken ${option.label}`);
                        setShowToastModal(true);
                      } else {
                        setMealType(option.label);
                        setIsModalOpen(false);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={option.icon} className="mr-2" />
                    {option.label}
                  </button>
                </React.Fragment>
              );
            })}
            <button
              className="mt-4 bg-gray-500 text-white text-medium px-8 py-2 rounded-md shadow-md shadow-blue-800/35"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {showToastModal && (
        <div 
          className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 ${isClosing ? 'opacity-0' : 'opacity-100'}`}
          onClick={() => setShowToastModal(false)}
        >
          <div 
            className={`bg-white p-6 rounded-lg shadow-xl relative transition-all duration-300 ${isClosing ? 'opacity-0 scale-95' : 'opacity-100 scale-100'}`}
            onClick={(e) => e.stopPropagation()}
          >
            <p className="text-lg font-semibold text-center whitespace-pre-line">
              {modalMessage}
            </p>
            <button
              className="mt-4 bg-blue-400 text-white text-medium px-4 py-2 rounded-md shadow-md shadow-blue-800/35"
              onClick={() => setShowToastModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default MealSnap;
