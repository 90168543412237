// src/component/mission/sub/Daily-01-Weigh.js
import React, { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faCrosshairs } from "@fortawesome/free-solid-svg-icons";
import Clock from "react-live-clock";
import CurrencyInput from "react-currency-input-field";
import { nanoid } from "nanoid";
import { dailyAtom } from "../../../../atom/daily/daily-atom";
import { userState } from "../../../../atom/atoms";
import { weightAtom, updateWeightAtomSelector } from "../../../../atom/fatloss/weight-atom";
import { coinEarnedAmountState, coinEarnedAnimationState } from "../../../../atom/coin/coin-atoms";
import { coinEarnedTodayState } from "../../../../atom/coin/coin-today-atom";
import { upLevelAtom } from "../../../../atom/up-level/up-level";
import { addWeightItem, getWeightByUserEmailAndDateTimeString } from "../../../../store/pouch/weight-pouch";
import { ClaimCoins, MyCoinHistory } from "../../../../util/CoinClaiming";
import { CheckUnclaimedLevelUps } from "../../../../util/UpLeveling";
import { getStartOfDayISOStringZ, getEndOfDayISOStringZ, getLocalDateOnly } from "../../../../util/Util";
import "./Set-Target.css";
import UpLevelMedal from "../../UpLevelMedal";
import vxInstance from "../../../../api/VxApiService";
import useWeighHeightBMIFromSurveyWeighing, { calculateIdealWeight } from '../../../../hooks/useWeighHeightBMIFromSurveyWeighing';
import WeightSlider from '../../weight/WeightSlider';
import { getUserObj } from "../../../../store/pouch/user-pouch";
import { updateUserTargetWeight } from '../../../../store/pouch/user-pouch'; // Import the new function
import { addUserObj } from '../../../../store/pouch/user-pouch'; // Import the addUserObj function

const SetTarget = () => {
  const [email, setEmail] = useState(null);
  const effectRan = useRef(false);
  const [user, setUser] = useRecoilState(userState);
  const [dailyState, setDailyState] = useRecoilState(dailyAtom);
  const [weightState, setWeightState] = useRecoilState(weightAtom);
  const [apiVersion, setApiVersion] = useState(process.env.REACT_APP_ENABLE_API_V1 ? "v1" : "v0");
  const [isShowError, setIsShowError] = useState(false);
  const { fetchUserWeightHeightBMI } = useWeighHeightBMIFromSurveyWeighing(email, setEmail, apiVersion);

  const { todaysWeight } = dailyState;
  const weightInputRef = useRef(null);
  const navigate = useNavigate();
  const [targetWeight, setTargetWeight] = useState(null);
  

  const handleWeightChange = (value, name) => {
    
    if (value === undefined || value === "") {
      setDailyState((prevState) => ({
        ...prevState,
        todaysWeight: "",
      }));
    } else {
      const numValue = parseFloat(value);
      if (!isNaN(numValue) && numValue >= 19.99 
      && numValue <= 199.99) {
        setDailyState((prevState) => ({
          ...prevState,
          todaysWeight: value,
        }));
      }
    }
  };

  const setTarget = async () => {
    try {

      let userObj = await getUserObj(user?.userEmail);
      let { _rev, ...updatedUser } = userObj;
      updatedUser.targetWeight = todaysWeight;
      setUser(updatedUser);
      await addUserObj(updatedUser);

      // Synchronize with the server
      vxInstance.userClientToServerUpsert(user?.accessToken, user?.refreshToken, updatedUser);

      console.log('Target weight set successfully:', targetWeight);
      navigate("/weight-history");
    } catch (error) {
      console.error('Error setting target weight:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && todaysWeight) {
      setTarget();
    }
  };

  const handleBack = () => {
    navigate("/daily");
  };

  useEffect(() => {
    if (effectRan.current) return;
    effectRan.current = true;

    const asyncFn = async() => {
      console.log("in set target");
      debugger;
      let token = null;
      let userEmail = null;
      let userObj = await getUserObj("active");
      if(user?.accessToken){
          token = {
              access_token: user?.accessToken,
              refresh_token: user?.refreshToken,
          };
          userEmail = user?.userEmail;
      }else if(userObj?.accessToken){
          token = {
              access_token: userObj?.accessToken,
              refresh_token: userObj?.refreshToken,
          };
          userEmail = userObj?.userEmail;
      }

      if (user?.userEmail) {
        const weightData = await vxInstance.getData("weight/get-weight", token?.access_token, token?.refresh_token);
        // const weightData = await getWeightByUserEmailAndDateTimeString(user.userEmail);
        
        // Assuming weightData is an array of weight entries
        if (weightData) {
          
          let data = weightData?.weightData?.map((entry) => ({
            date: new Date(entry?.weightTxDateTime).toLocaleDateString(),
            weight: entry?.weightKg,
          }));

          // Upsert logic for weight history
          setWeightState((prevState) => {
            
            const existingWeightHistory = prevState.weightHistory;

            // Create a new weight history array with upsert logic
            const updatedWeightHistory = weightData?.weightData?.reduce((acc, newWeight) => {
              const existingIndex = acc.findIndex(item => item._id === newWeight._id);
              if (existingIndex > -1) {
                // Update existing entry
                acc[existingIndex] = {
                  ...acc[existingIndex],
                  ...newWeight, // Update the existing entry with new data
                };
              } else {
                // Add new entry
                acc.push(newWeight);
              }
              return acc;
            }, [...existingWeightHistory]);

            // Return the updated state
            return {
              ...prevState,
              weightHistory: updatedWeightHistory,
            };
          });

          if (weightData) {
            
            let data = weightData?.weightData?.map((entry) => ({
              date: new Date(entry?.weightTxDateTime).toLocaleDateString(),
              weight: entry?.weightKg,
            }));
            // Update the weightAtom with the fetched data
            setWeightState((prevState) => ({
              ...prevState,
              weightHistory: [...weightData?.weightData],
            }));
          }
        }
      }

      let userWeightHeightInfo = await fetchUserWeightHeightBMI();
      console.log(userWeightHeightInfo?.latestWeight);
      
      if (userWeightHeightInfo?.latestWeight) {
        // Update only the `todaysWeight` field in `dailyState`
        setDailyState((prevState) => ({
          ...prevState,
          todaysWeight: userWeightHeightInfo?.latestWeight,
        }));
      }
    };
    asyncFn();
  });

  return (
    <div className="daily-weigh">
      <div className="header-container">
        <button onClick={handleBack} className="back-button">
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      </div>
      <div className="flex justify-items-center align-items-center mb-7 text-5xl">
        <FontAwesomeIcon icon={faCrosshairs} size=""/>
      </div>
      
      <div className="weight-input-container w-full">
        <CurrencyInput
          id="weight-input"
          name="weight-input"
          placeholder="Enter weight (kg)"
          decimalsLimit={2}
          decimalScale={2}
          maxLength={6}
          value={todaysWeight}
          onValueChange={handleWeightChange}
          allowNegativeValue={false}
          decimalSeparator="."
          groupSeparator=""
          ref={weightInputRef}
          className="weight-input "
          onKeyPress={handleKeyPress}
        />

        <WeightSlider
          weight={todaysWeight}
          onWeightChange={handleWeightChange}
        />
        <div className={`text-red-500 ${isShowError ? "visible" : "hidden"}`}>
          Please enter a valid weight.
        </div>
      </div>
      <div className="button-container w-3/4">
        {(
          <button
            onClick={setTarget}
            className="weigh-button bg-blue-400 rounded-md shadow-md shadow-blue-800/35 mb-2"
          >
            Set Target
          </button>
        )}
        
      </div>
      <UpLevelMedal />
    </div>
  );
};

export default SetTarget;
