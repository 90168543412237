// src/component/quiz/FloatingPointer.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointRight, faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import './FloatingPointer.css'; // We'll create this CSS file

const FloatingPointer = ({ logicToHide, clickHandler }) => {
  return (
    <div className="flex items-center">
      <div 
        className="w-1/4 cursor-pointer relative"
        onClick={clickHandler}
      >
        {!logicToHide && (
          <FontAwesomeIcon
            icon={faHandPointRight}
            className="absolute top-1/2 -right-16 transform -translate-y-1/2 text-blue-500 floating-hand"
            size="2xl"
          />
        )}
      </div>
    </div>
  );
};

export default FloatingPointer;