import React, { useEffect, useRef, useState } from "react";
import {
  MinChatUiProvider,
  MainContainer,
  MessageInput,
  MessageContainer,
  MessageList,
  MessageHeader,
} from "@minchat/react-chat-ui";
import { useNavigate, useLocation } from "react-router-dom";
import Typewriter from "typewriter-effect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBurger,
  faChevronLeft,
  faPencilSquare,
} from "@fortawesome/free-solid-svg-icons";
import "./Feedback.css";
import Modal from "react-modal";
import {
  buildConversation,
  formatTime,
  getEndOfDayISOStringZ,
  getLocalDateOnly,
  getStartOfDayISOStringZ,
} from "../../../util/Util";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { activeTopicIdState, chatAtom } from "../../../atom/chat/chat-atom";
import { nanoid } from "@reduxjs/toolkit";
import {
  addChatItem,
  getChatsByUserEmail,
  getChatsByUserEmailAndDateTimeString,
  getChatsByUserEmailAndTopicId,
  getUniqueChatTopics,
} from "../../../store/pouch/chat-pouch";
import { userState } from "../../../atom/atoms";
import {
  coinEarnedAmountState,
  coinEarnedAnimationState,
} from "../../../atom/coin/coin-atoms";
import { coinEarnedTodayState } from "../../../atom/coin/coin-today-atom";
import vxInstance from "../../../api/VxApiService";

const Feedback = () => {
    const user = useRecoilValue(userState);
    const [activeTopicState, setActiveTopicState] =
    useRecoilState(activeTopicIdState);
    const [isTyping, setIsTyping] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [topicList, setTopicList] = useState([]);
    const setCoinEarnedAmount = useSetRecoilState(coinEarnedAmountState);
    const setCoinEarnedAnimation = useSetRecoilState(coinEarnedAnimationState);
    const setCoinEarnedToday = useSetRecoilState(coinEarnedTodayState);
    const effectRan = useRef(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const starRating = queryParams.get("starRating"); // Get the starRating from query params

    const greetings = [
        "What feedback do you have about healthChat?",
        "Can I get your opinions about healthChat?",
        "How is your overall experience with healthChat?",
        "What you would like to see added or improved in healthChat?",
        "What do you think healthChat could do better in future.",
        "What features you found helpful or lacking in healthChat?",
        "Would you recommend healthChat to friend or family? Why / why not?",
    ];
    const topicOfGreeting = [
        "feedback",
        "opinions",
        "experience",
        "improve",
        "better",
        "features",
        "recommend",
    ];
    const [topicIndex, setTopicIndex] = useState(() => {
        return Math.floor(Math.random() * greetings.length);
    });
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);

    const handleModalOpen = () => {
        setIsModalOpen(!isModalOpen);
        document.body.style.overflow = "hidden";
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        document.body.style.overflow = "auto";
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleNewChat = () => {
        let newRandom = Math.floor(Math.random() * greetings.length);
        if (newRandom === topicIndex)
        newRandom = (newRandom + 1) % greetings.length;
        setTopicIndex(newRandom);
        setMessages([]);
        handleModalClose();
    };

    const handleSendMessage = (message) => {
        const asyncWrapper = async () => {
            //add message Thanks for rating us 5 star
        let ratingMsg = buildConversation(
            new Date(),
            `Thanks for rating us ${starRating} star${starRating > 1 ? 's': ''}.`,
            "healthChat",
            "healthChat",
            `${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`,
        );

        let greetingMsg = buildConversation(
            new Date(),
            greetings[topicIndex],
            "healthChat",
            "healthChat",
            `${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`,
        );
        debugger;
        if (messages && messages.length === 0) {
            setMessages((prevMessages) => [...prevMessages, ratingMsg, greetingMsg]);
        }
        setMessages((prevMessages) => [
            ...prevMessages,
            ...(message && message.trim()
            ? [{ text: message, user: { id: user?.userEmail, name: user?.userEmail } }]
            : []),
        ]);

        setIsTyping(true);
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

        // Send messages with a delay
        await delay(500);
        let thankYou4 = buildConversation(
            new Date(),
            `Thank you for your humble feedback.`,
            "healthChat",
            "healthChat",
            `${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`,
        );
        await delay(1500);
        let soHappy = buildConversation(
            new Date(),
            `I am so happy talking to you.`,
            "healthChat",
            "healthChat",
            `${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`,
        );
        setMessages((prevMessages) => [...prevMessages, thankYou4, soHappy]);

        
        setIsTyping(false);
        let newTopicId = activeTopicState;
        let newTopicName = topicOfGreeting[topicIndex];
        let chatTopicDateTime = null;

        if (activeTopicState) {
            let activeTopicResult = await getChatsByUserEmailAndTopicId(
            user?.userEmail,
            activeTopicState,
            );
            if (!(activeTopicResult && activeTopicResult.length)) {
            newTopicId = nanoid(10);
            chatTopicDateTime = new Date().toISOString();
            } else {
            newTopicId = activeTopicResult?.chatTopicId;
            chatTopicDateTime = activeTopicResult?.chatTopicDateTime;
            }
        } else {
            newTopicId = nanoid(10);
            setActiveTopicState(newTopicId);
        }
        let todayDate = getLocalDateOnly();
        if (messages && messages.length === 0) {
            setActiveTopicState(newTopicId);

            chatTopicDateTime = new Date().toISOString();
            let greetingChatJson = {
            _id: `${user?.userEmail}-${newTopicId}-healthChat-${chatTopicDateTime}`,
            userEmail: user?.userEmail,
            initiatingParty: "healthChat",
            chatTopicId: newTopicId,
            chatTopic: newTopicName,
            chatTopicDateTime: chatTopicDateTime,
            chatDate: todayDate,
            chatDateTime: chatTopicDateTime,
            chatText: greetingMsg.text,
            chatAvatar: `${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`,
            };
            await addChatItem(greetingChatJson);

            let topicArray = await getUniqueChatTopics(user?.userEmail);
            setTopicList(topicArray);
            if (newTopicId) {
            let activeTopicResult = await getChatsByUserEmailAndTopicId(
                user?.userEmail,
                newTopicId,
            );
            if (activeTopicResult && activeTopicResult.length) {
                let arrayOfChats = await getChatsByUserEmailAndTopicId(
                user?.userEmail,
                newTopicId,
                );
                if (arrayOfChats && arrayOfChats.length) {
                arrayOfChats = arrayOfChats.map((e) => {
                    return buildConversation(
                    e?.chatDateTime,
                    e?.chatText,
                    e?.initiatingParty,
                    e?.initiatingParty,
                    e?.chatAvatar,
                    );
                });
                }
            }
            }
        }
        const userChatTime = new Date().toISOString();
        let userChatJson = {
            _id: `${user?.userEmail}-${newTopicId}-user-${userChatTime}`,
            userEmail: user?.userEmail,
            initiatingParty: "user",
            chatTopicId: newTopicId,
            chatTopic: newTopicName,
            chatTopicDateTime: chatTopicDateTime,
            chatDate: todayDate,
            chatDateTime: userChatTime,
            chatText: message,
            chatAvatar: null,
            chatIsFeedback: 1,
            chatFeedbackStarRating: starRating,
        };
        await addChatItem(userChatJson);

        //get chat by email
        let chatByEmail = await getChatsByUserEmail(user?.userEmail);
        //upsert
        debugger;
        await vxInstance.chatClientToServerUpsert(user?.accessToken, user?.refreshToken, { userEmail: user?.userEmail, chatData: chatByEmail });

        //   let coinEarnChat = await getCoinById(
        //     `${user?.userEmail}-CHAT-${todayDate}`,
        //   );
        //   if (!coinEarnChat) {
        //     let toEarnArray = await ClaimCoins();
        //     if (toEarnArray.length) {
        //       setCoinEarnedAmount(
        //         toEarnArray.reduce(
        //           (sum, transaction) => sum + transaction.coinsEarned,
        //           0,
        //         ),
        //       );
        //       setCoinEarnedAnimation(true);

        //       let coinsTodayArray = await MyCoinHistory(
        //         user?.userEmail,
        //         getStartOfDayISOStringZ(),
        //         getEndOfDayISOStringZ(),
        //       );

        //       let coinToday = coinsTodayArray?.reduce(
        //         (sum, transaction) => sum + transaction.coinsEarned,
        //         0,
        //       );
        //       setCoinEarnedToday(coinToday);
        //     }
        //   }
        };
        asyncWrapper();
    };

  useEffect(() => {
    if (effectRan.current === false) {
      const asyncWrapper = async () => {
        let topicArray = await getUniqueChatTopics(user?.userEmail);
        setTopicList(topicArray);
        if (activeTopicState) {
          let activeTopicResult = await getChatsByUserEmailAndTopicId(
            user?.userEmail,
            activeTopicState,
          );
          if (activeTopicResult && activeTopicResult.length) {
            let arrayOfChats = await getChatsByUserEmailAndTopicId(
              user?.userEmail,
              activeTopicState,
            );
            if (arrayOfChats && arrayOfChats.length) {
              arrayOfChats = arrayOfChats.map((e) => {
                return buildConversation(
                  e?.chatDateTime,
                  e?.chatText,
                  e?.initiatingParty,
                  e?.initiatingParty,
                  e?.chatAvatar,
                );
              });
              debugger;
              setMessages(arrayOfChats);
            }
          }
        }
        effectRan.current = true;
      };
      asyncWrapper();
    }
  }, [user?.userEmail, setTopicList, activeTopicState]);

  const handleTopic = (topicId) => {
    const asyncWrapper = async () => {
      let activeTopicResult = await getChatsByUserEmailAndTopicId(
        user?.userEmail,
        topicId,
      );
      if (activeTopicResult && activeTopicResult.length) {
        //topic email match
        let arrayOfChats = await getChatsByUserEmailAndTopicId(
          user?.userEmail,
          topicId,
        );
        if (arrayOfChats && arrayOfChats.length) {
          setActiveTopicState(topicId);
          arrayOfChats = arrayOfChats.map((e) => {
            return buildConversation(
              e?.chatDateTime,
              e?.chatText,
              e?.initiatingParty,
              e?.initiatingParty,
              e?.chatAvatar,
            );
          });
          setMessages(arrayOfChats);
          handleModalClose();
        }
      }
    };
    asyncWrapper();
  };

  return (
    <>
      <div className="backButton" onClick={handleBack} />
      <div className="hamburger-container" onClick={handleModalOpen}>
        <FontAwesomeIcon icon={faBars} size="lg" />
        <span style={{ marginLeft: "10px" }}>Topics</span>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        portalClassName="modal-portal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            position: "fixed",
            zIndex: 1000,
          },
          content: {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80vw",
            height: "90vh",
            backgroundColor: "white",
            padding: "20px",
            position: "absolute",
          },
        }}
      >
        <>
          <div className="mb-3" onClick={handleNewChat}>
            <button className="rounded bg-blue-400 shadow-md shadow-blue-800/35 p-2 text-white ">
              <FontAwesomeIcon icon={faPencilSquare} /> New Chat
            </button> <input
            type="text"
            // value={localNickname}
            // onChange={handleInputChange}
            // onBlur={handleBlur}
            // onKeyDown={handleNickNameKeyDown}
            placeholder="QuickSearch"
            className="border p-2 rounded"
            maxLength={15}
          />
          </div>
          <ul className="space-y-3">
            {topicList.map((topic) => (
              <li key={topic.chatTopicId}>
                <button
                  className="rounded bg-blue-400 shadow-md shadow-blue-800/35 p-2 text-white"
                  onClick={() => handleTopic(topic.chatTopicId)}
                >
                  {topic.chatTopic}
                </button>
              </li>
            ))}
          </ul>
        </>
        <button
          className="rounded-xl bg-gray-600 text-white font-medium px-5 py-3 mt-3"
          onClick={handleModalClose}
        >
          Cancel
        </button>
      </Modal>
      <MinChatUiProvider theme="#6ea9d7">
        <MainContainer style={{ height: "91vh", width: "100vw" }}>
          <MessageContainer>
            <MessageHeader />
            {messages.length === 0 ? (
              <>
                <div className="chatbot-image-container">
                  <img
                    src={`${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`}
                    alt="Chatbot"
                    className="chatbot-image"
                  />
                </div>
                {starRating && (
                  <div className="text-center text-xl text-blue-600 mb-10">
                    Thanks for rating us {starRating} star{starRating > 1 ? 's' : ''}.
                  </div>
                )}
                <div className="text-center text-xl font-thin text-blue-600 mb-10 mx-3">
                  <Typewriter
                    options={{
                      strings: 
                      greetings[topicIndex],
                      autoStart: true,
                      loop: false,
                      deleteSpeed: Infinity,
                      cursor: "",
                    }}
                  />
                </div>
              </>
            ) : (
              <MessageList
                messages={messages.map((msg) => ({
                  ...msg,
                  user: {
                    ...msg.user,
                    name: `${msg.user?.name ?? ""} (${formatTime(msg.timestamp)})`,
                  },
                }))}
              />
            )}
            {isTyping && (
              <div className="typing-text">
                <span className="typing-font">healthChat is typing</span>
                <img
                  src={`${process.env.PUBLIC_URL}/chat2/gif-typing.gif`}
                  alt="Typing indicator"
                  style={{ width: "40px", height: "auto" }}
                />
              </div>
            )}
            <MessageInput
              onSendMessage={handleSendMessage}
              placeholder="Feedback to healthChat AI"
            />
          </MessageContainer>
        </MainContainer>
      </MinChatUiProvider>
    </>
  );
};

export default Feedback;