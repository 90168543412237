import weightDB, { getWeightByUserEmailAndDateTimeString } from '../store/pouch/weight-pouch';
import { startOfDay, subDays } from 'date-fns';
import { toZonedTime as utcToZonedTime, fromZonedTime as zonedTimeToUtc } from "date-fns-tz";
import { addLevelUp, getLastLevelUp, getLevelUpByLevel } from '../store/pouch/level-up-pouch';

export const performLevelUp = async (userEmail, currentLevel) => {
  const newLevel = currentLevel + 1;
  await addLevelUp(userEmail, newLevel);
  return newLevel;
};

export const CheckUnclaimedLevelUps = async (userEmail, userLevel) => {
  try {
    debugger;
    if (userLevel && userLevel !== 1) {
      return false;
    }

    const lastLevelUp = await getLevelUpByLevel(userEmail, userLevel);
    if (lastLevelUp) {
      const lastLevelUpDate = new Date(lastLevelUp.levelUpDateTime);
      const now = new Date();
      if (now.getTime() - lastLevelUpDate.getTime() < 24 * 60 * 60 * 1000) {
        // If less than 24 hours have passed since the last level up, return false
        return false;
      }
    }

    const timeZone = 'Asia/Kuala_Lumpur';
    const now = new Date();
    const malaysiaTime = utcToZonedTime(now, timeZone);
    const yesterdayStart = zonedTimeToUtc(startOfDay(subDays(malaysiaTime, 1)), timeZone);
    // const twoDaysAgoStart = zonedTimeToUtc(startOfDay(subDays(malaysiaTime, 2)), timeZone);
    const todayStart = zonedTimeToUtc(startOfDay(malaysiaTime), timeZone);

    const result = await getWeightByUserEmailAndDateTimeString(userEmail, yesterdayStart.toISOString(), now.toISOString());

    debugger;
    const weights = result?.docs;

    if (weights?.length < 2) {
      return false;
    }

    // Check if there are weights on two consecutive days
    let hasYesterdayWeight = false;
    // let hasTwoDaysAgoWeight = false;
    let hasTodayWeight = false;

    if(weights && Array.isArray(weights)){  
      for (const weight of weights) {
        const weightDate = utcToZonedTime(new Date(weight.weightTxDateTime), timeZone);
        const weightDayStart = startOfDay(weightDate);

        if (weightDayStart.getTime() === yesterdayStart.getTime()) {
          hasYesterdayWeight = true;
        } else if (weightDayStart.getTime() === todayStart.getTime()) {
          hasTodayWeight = true;
        }

        if (hasYesterdayWeight && hasTodayWeight) {
          return true;
        }
      }
    }

    return false;
  } catch (error) {
    console.error("Error checking for unclaimed level ups:", error);
    return false;
  }
};

// You can add more leveling-related functions here in the future
const Level2ToLevel3 = () => {
    //weigh consecutively 3 days
    //first 2 days are almost stagnant
    //the 3rd day get weight improvement
}

const Level3ToLevel4 = () => {
    //has maintained at least 1 mission for 4 days streak
    //has maintained at least 1 lifestyle for 4 days streak
    //has avoided at least 1 bad habit for 4 days streak
}

