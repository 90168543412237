import React, { useState, useEffect, useRef } from "react";
import MealItem from "./MealItem";
import MealDetailModal from "./MealDetailModal";
import { useNavigate } from "react-router-dom";
import { userState } from "../../atom/atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { getMealsByUserEmailAndEpoch } from "../../store/pouch/meal-pouch";
import { mealAtom } from "../../atom/meal/meal-atom";
import { format, isToday, isYesterday, isThisWeek, isThisYear } from "date-fns";
import { groupMealsByTimeCluster } from '../../util/Util'; // Assuming this is the correct import

const MealGallery = () => {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const effectRan = useRef(false);
  const [mealsState, setMealsState] = useRecoilState(mealAtom);
  const [groupedMeals, setGroupedMeals] = useState({ hasMeals: false });
  const [selectedMeal, setSelectedMeal] = useState(null);

  const handleBack = () => {
    navigate("/mealsnap");
  };

  const handleMealClick = (meal) => {
    debugger;
    setSelectedMeal(meal);
  };

  const handleCloseModal = () => {
    setSelectedMeal(null);
  };

  useEffect(() => {
    const asyncWrapper = async () => {
      let mealRecords = await getMealsByUserEmailAndEpoch(
        user?.userEmail,
        "2014-01-01T00:00:00.000Z",
        "2034-01-01T00:00:00.000Z",
      );

      setMealsState((prevState) => ({
        ...prevState,
        mealGallery: mealRecords,
      }));

      setGroupedMeals(groupMealsByTimeCluster(mealRecords));
    };

    if (effectRan.current === false) {
      asyncWrapper();
      effectRan.current = true;
    }
  }, [setMealsState, user?.userEmail]);

  const getTimeCluster = (date) => {
    if (isToday(date)) {
      return "Today";
    } else if (isYesterday(date)) {
      return "Yesterday";
    } else if (isThisWeek(date)) {
      return "This Week";
    } else if (isThisYear(date)) {
      return format(date, "MMMM");
    } else {
      return format(date, "yyyy");
    }
  };

  const groupMealsByTimeCluster = (meals) => {
    const grouped = { hasMeals: false };
    meals.forEach((meal) => {
      const date = new Date(meal.mealDateTime);
      const cluster = getTimeCluster(date);
      if (!grouped[cluster]) {
        grouped[cluster] = [];
      }
      grouped[cluster].push(meal);
      grouped.hasMeals = true;
    });
    return grouped;
  };

  return (
    <>
      <button onClick={handleBack} className="backButton"></button>
      {groupedMeals.hasMeals ? (
        <div className="flex flex-col p-4">
          {Object.entries(groupedMeals).map(([cluster, meals]) => (
            cluster !== 'hasMeals' && (
              <div key={cluster} className="mb-6">
                <h2 className="text-xl font-bold mt-5 mb-2">{cluster}</h2>
                <div className="flex flex-wrap justify-start gap-4">
                  {meals.map((meal, index) => (
                    <>
                      <div
                        onClick={() => handleMealClick(meal)} >
                        <MealItem key={index} meal={meal} />
                      </div>
                    </>
                  ))}
                </div>
              </div>
            )
          ))}
        </div>
      ) : (
        <div className="mt-16 text-blue-400 shadow">
          You do not have any meal history.
        </div>
      )}
      {selectedMeal && <MealDetailModal meal={selectedMeal} onClose={handleCloseModal} />}
    </>
  );
};

export default MealGallery;
