import { useRecoilState, useSetRecoilState } from 'recoil';
import { questionAnswerState } from '../atom/survey/survey-questions-atoms';
import { getSurveyQuestion } from '../store/pouch/survey-questions-pouch';
import { addUserObj, getUserObj } from '../store/pouch/user-pouch';
import { addLevelUp, getLastLevelUp } from '../store/pouch/level-up-pouch';
import { userState } from '../atom/atoms';
import { addSurvey, getSurvey } from '../store/pouch/survey-pouch';
import { currentQuestionState, surveyCompletionState } from '../atom/survey/survey-atoms';
import vxOAuthInstance from '../api/VxOAuthService';
import vxInstance from '../api/VxApiService';
import { addWeightItem } from '../store/pouch/weight-pouch';

const useWeighHeightBMIFromSurveyWeighing = (email, setEmail, apiVersion = "v0") => {
    const [user, setUser] = useRecoilState(userState);
    const [questionAnswer100, setQuestionAnswer100] = useRecoilState(questionAnswerState("100"));
    const [questionAnswer200, setQuestionAnswer200] = useRecoilState(questionAnswerState("200"));
    const [questionAnswer300, setQuestionAnswer300] = useRecoilState(questionAnswerState("300"));
    const [questionAnswer400, setQuestionAnswer400] = useRecoilState(questionAnswerState("400"));
    const [questionAnswer500, setQuestionAnswer500] = useRecoilState(questionAnswerState("500"));
    const [questionAnswer501, setQuestionAnswer501] = useRecoilState(questionAnswerState("501"));
    const [questionAnswer600, setQuestionAnswer600] = useRecoilState(questionAnswerState("600"));
    const [questionAnswer700, setQuestionAnswer700] = useRecoilState(questionAnswerState("700"));
    const [questionAnswer800, setQuestionAnswer800] = useRecoilState(questionAnswerState("800"));
    const [questionAnswer900, setQuestionAnswer900] = useRecoilState(questionAnswerState("900"));
    const [questionAnswer1000, setQuestionAnswer1000] = useRecoilState(questionAnswerState("1000"));

    const fetchUserWeightHeightBMI = async () => {
        
        let latestWeight=0, latestWeightEntry = 0, bmi = 0, heightBySurvey = 0, latestWeightBySurvey = 0;
        let token = null;
        let userEmail = null;
        
        let userObj = await getUserObj("active");
        if(user?.accessToken){
            token = {
                access_token: user?.accessToken,
                refresh_token: user?.refreshToken,
            };
            userEmail = user?.userEmail;
        }else if(userObj?.accessToken){
            token = {
                access_token: userObj?.accessToken,
                refresh_token: userObj?.refreshToken,
            };
            userEmail = userObj?.userEmail;
        }

        const userData = await vxInstance.getData("user/get-user", token?.access_token, token?.refresh_token);
        
        
        
        let latestWeightByUserProfile = userData?.latestWeight;
        let userHeightInCm = userData?.userHeightCm && userData?.userHeightCm * 1;
        if(!latestWeightByUserProfile){
            const surveyData = await vxInstance.getData("survey/get-survey", token?.access_token, token?.refresh_token);
            heightBySurvey = surveyData?.surveyData?.find(item => item._id === userEmail + "-surveyQNum-700" )?.surveyAnswerString;
            if(userHeightInCm && userHeightInCm > 200){
                userHeightInCm = heightBySurvey*1;
                setUser({...user, userHeightCm: userHeightInCm });
                await addUserObj({...user, userHeightCm: userHeightInCm });
                vxInstance.userClientToServerUpsert(user?.accessToken, user?.refreshToken, {...user, userHeightCm: userHeightInCm });
            }
            const weightData = await vxInstance.getData("weight/get-weight", token?.access_token, token?.refresh_token);
            latestWeightBySurvey = surveyData?.surveyData?.find(item => item._id === userEmail + "-surveyQNum-600" )?.surveyAnswerString;
            const sortedWeightData = weightData?.weightData?.sort((a, b) => {
                const dateA = new Date(a.weightTxDateTime);
                const dateB = new Date(b.weightTxDateTime);
                // Sort in descending order (most recent first)
                return dateB - dateA;
            });
                
            heightBySurvey = heightBySurvey * 1;
            latestWeightEntry = (sortedWeightData.length ? sortedWeightData[0] : 0);
            let bmi = 0;
            if (heightBySurvey > 0) {
                bmi = latestWeight / ((heightBySurvey / 100) ** 2);
                bmi = parseFloat(bmi.toFixed(2)); // Round to 2 decimal places
            }
            for (const item of sortedWeightData) {
                await addWeightItem(item);
            }
        } else if(userHeightInCm && userHeightInCm <= 200){
            heightBySurvey = userHeightInCm;
        } else if(userHeightInCm && userHeightInCm > 200){
            const surveyData = await vxInstance.getData("survey/get-survey", token?.access_token, token?.refresh_token);
            heightBySurvey = surveyData?.surveyData?.find(item => item._id === userEmail + "-surveyQNum-700" )?.surveyAnswerString;
            userHeightInCm = heightBySurvey*1;
            setUser({...user, userHeightCm: userHeightInCm });
            await addUserObj({...user, userHeightCm: userHeightInCm });
            vxInstance.userClientToServerUpsert(user?.accessToken, user?.refreshToken, {...user, userHeightCm: userHeightInCm });
        }
        latestWeight = (latestWeightByUserProfile ? latestWeightByUserProfile : 
            (latestWeightEntry ? latestWeightEntry.weightKg : latestWeightBySurvey*1)
        );

        return {
            latestWeight: latestWeight,
            height: heightBySurvey,
            bmi: bmi,
        };
    };
    return { fetchUserWeightHeightBMI };
};

export function calculateIdealWeight(heightCm) {
    // Convert height from cm to meters
    const heightM = heightCm / 100;
    
    // Calculate ideal weight using BMI formula: BMI = weight / (height^2)
    // We use BMI of 23.5 as the target
    const idealWeightKg = 23.5 * (heightM * heightM);
    
    // Round to one decimal place
    return Math.round(idealWeightKg * 10) / 10;
  }
export function calculateTargetBMIForTargetWeight(heightCm, targetWeight) {
    if (heightCm <= 0 || targetWeight <= 0) {
        return null; // Return null for invalid inputs
    }

    const heightM = heightCm / 100; // Convert height from cm to meters
    const targetBMI = targetWeight / (heightM * heightM); // Calculate BMI

    return parseFloat(targetBMI.toFixed(2)); // Round to 2 decimal places
}

export default useWeighHeightBMIFromSurveyWeighing;
