import vxInstance from "../api/VxApiService";
import { getCaloriesByUserEmailAndDateTimeString } from "../store/pouch/calorie-pouch";
import { getChatsByUserEmailAndDateTimeString } from "../store/pouch/chat-pouch";
import {
  addCoinItem,
  getCoinById,
  getCoinsByUserEmailAndDateTimeString,
} from "../store/pouch/coin-pouch";
import {
  getHabitQuestion,
  getHabitQuestionsByIds,
  getHabitQuestionsByUserEmailAndDateTimeRange,
} from "../store/pouch/habit-pouch";
import {
  getLifestyleQuestion,
  getLifestyleQuestionsByIds,
} from "../store/pouch/lifestyle-pouch";
import {
  getMealsByUserEmailAndDateTimeString,
  getMealsByUserEmailAndEpoch,
} from "../store/pouch/meal-pouch";
import { getSurvey } from "../store/pouch/survey-pouch";
import { getUserObj } from "../store/pouch/user-pouch";
import {
  getWeightByUserEmailAndDateTimeRange,
  getWeightByUserEmailAndDateTimeString,
} from "../store/pouch/weight-pouch";
import {
  convertYYYYMMDDToDateObj,
  convertYYYYMMDDToDateObjEOD,
  getEndOfDayISOStringZ,
  getLocalDateOnly,
  getLocalDateTimeObj,
  getStartOfDayISOStringZ,
} from "./Util";
import {
  format,
  isToday,
  isYesterday,
  isThisWeek,
  isThisYear,
  formatDistanceToNow,
  startOfDay,
} from "date-fns";
import { toZonedTime as utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

// coinTxDateTime: new Date().toISOString(),
export const CalculateCoinsUnclaimed = async () => {
  //survey complete 10 coins
  let totalToBeEarnedArray = [];
  let activeUser = await getUserObj("active");
  let userEmail = activeUser?.userEmail;
  let surveyComplete = await getSurvey(userEmail);
  let surveyCoinTransaction = await getCoinById(`${userEmail}-SURVEY-COMPLETE`);
  if (
    surveyComplete?.isComplete &&
    !surveyCoinTransaction
  ) {
    totalToBeEarnedArray.push({
      _id: `${userEmail}-SURVEY-COMPLETE`,
      userEmail: userEmail,
      coinPurpose: "SURVEY-COMPLETE",
      coinsEarned: 10,
      coinTxDateTime: new Date().toISOString(),
    });
  }

  let todayDate = getLocalDateOnly();
  let weighingToday = await getWeightByUserEmailAndDateTimeString(
    userEmail,
    getStartOfDayISOStringZ(),
    getEndOfDayISOStringZ(),
  );
  let coinEarnedWeighingToday = await getCoinById(
    `${userEmail}-WEIGHING-${todayDate}`,
  );
  if (
    Array.isArray(weighingToday) &&
    weighingToday.length > 0 &&
    !coinEarnedWeighingToday
  ) {
    totalToBeEarnedArray.push(buildTodayCoin(userEmail, "WEIGHING", 10));
  }
  // lifestyle 10 coins
  let lifestyleToday = await getLifestyleQuestion(
    `${userEmail}-lifestyle-${todayDate}-1000`,
  );
  let coinEarnedLifestyleToday = await getCoinById(
    `${userEmail}-LIFESTYLE-${todayDate}`,
  );
  if (lifestyleToday && !coinEarnedLifestyleToday) {
    totalToBeEarnedArray.push(buildTodayCoin(userEmail, "LIFESTYLE", 10));
  }
  //habit 10 coins
  let habitToday = await getHabitQuestion(`${userEmail}-habit-${todayDate}-6`);
  if (habitToday) {
    let coinEarnedHabitToday = await getCoinById(
      `${userEmail}-HABIT-${todayDate}`,
    );
    if (!coinEarnedHabitToday) {
      totalToBeEarnedArray.push(buildTodayCoin(userEmail, "HABIT", 10));
    }
  }
  let coinEarnedExerciseToday = await getCoinById(
    `${userEmail}-EXERCISE-${todayDate}`,
  );
  // debugger;
  if (!coinEarnedExerciseToday) {
    let exerciseTodayArray = await getCaloriesByUserEmailAndDateTimeString(
      userEmail,
      getStartOfDayISOStringZ(),
      getEndOfDayISOStringZ(),
      "exercise",
    );
    if (exerciseTodayArray.length) {
      totalToBeEarnedArray.push(buildTodayCoin(userEmail, "EXERCISE", 10));
    }
  }

  let coinEarnedChatToday = await getCoinById(`${userEmail}-CHAT-${todayDate}`);
  if (!coinEarnedChatToday) {
    let todayChatArray = await getChatsByUserEmailAndDateTimeString(
      userEmail,
      getStartOfDayISOStringZ(),
      getEndOfDayISOStringZ(),
    );
    if (todayChatArray.length) {
      totalToBeEarnedArray.push(buildTodayCoin(userEmail, "CHAT", 10));
    }
  }

  let coinEarnedMealToday = await getCoinById(`${userEmail}-MEAL-${todayDate}`);
  if (!coinEarnedMealToday) {
    let todayMealArray = await getMealsByUserEmailAndEpoch(
      userEmail,
      getStartOfDayISOStringZ(),
      getEndOfDayISOStringZ(),
    );
    if (todayMealArray.length) {
      totalToBeEarnedArray.push(buildTodayCoin(userEmail, "MEAL", 10));
    }
  }

  return totalToBeEarnedArray;
};

export const ClaimCoins = async () => {
  // debugger;
  let totalToBeEarnedArray = await CalculateCoinsUnclaimed();
  if (Array.isArray(totalToBeEarnedArray) && totalToBeEarnedArray.length > 0) {
    totalToBeEarnedArray.forEach(async (e) => {
      let response = await addCoinItem(e);
      console.log("coin added : ", e, "resp : ", response);
    });
  }
  return totalToBeEarnedArray;
};

export const ClaimCoinsOnline = async () => {
  let userObj = await getUserObj("active");
  let coinEarnedCheckinToday = await getCoinById(`${userObj?.userEmail}-CHECKIN-${getLocalDateOnly()}`);
  let coinEarnedCheckinTodayArray = [];
  if (!coinEarnedCheckinToday) {
    let coinItem = buildTodayCoin(userObj?.userEmail, "CHECKIN", 10);
    coinEarnedCheckinTodayArray.push(coinItem);
    let response = await addCoinItem(coinItem);
    console.log("coin added : ", response);
  }
  return coinEarnedCheckinTodayArray;
};

export const buildTodayCoin = (userEmail, coinPurpose, coinAmount) => {
  return {
    _id: `${userEmail}-${coinPurpose}-${getLocalDateOnly()}`,
    userEmail: userEmail,
    coinPurpose: coinPurpose,
    coinsEarned: coinAmount,
    coinTxDateTime: new Date().toISOString(),
  };
};

export const MyCoinHistory = async (
  userEmail = null,
  startDate = null,
  endDate = null,
) => {
  userEmail = userEmail || (await getUserObj("active")?.value?.userEmail);
  let coinTransaction = await getCoinsByUserEmailAndDateTimeString(
    userEmail,
    startDate || "2014-01-01T00:00:00.000Z",
    endDate || "2034-01-01T23:59:59.999Z",
  );
  return coinTransaction;
};

export const getCoinAmountEarnedToday = async (userEmailParam = null) => {
  let userEmail =
    (await getUserObj("active")?.value?.userEmail) || userEmailParam;

  let coinTransaction = await getCoinsByUserEmailAndDateTimeString(
    userEmail,
    getStartOfDayISOStringZ(),
    getEndOfDayISOStringZ(),
  );
  let coinToday = coinTransaction.reduce(
    (sum, transaction) => sum + transaction.coinsEarned,
    0,
  );
  return coinToday;
};
