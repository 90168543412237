import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
const HomeTips = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center p-6">
        <div
          className="text-blue-400 font-normal text-3xl mb-5"
          style={{ textShadow: "1.5px 1.5px 3px rgba(0, 0, 0, 0.3)" }}
        >
          <FontAwesomeIcon icon={faLightbulb} /> Suggested For You
        </div>
        <div
          className="text-blue-400 font-normal text-2xl mb-2"
          style={{ textShadow: "1.5px 1.5px 3px rgba(0, 0, 0, 0.3)" }}
        >
          Do you know?
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/daily2/03-malaysia-obese.jpeg`}
          alt="Malaysia Obese"
          className="mb-6 w-full"
        />
        <div
          className=" text-center text-blue-400 font-normal text-xl mb-12"
          style={{ textShadow: "1.5px 1.5px 3px rgba(0, 0, 0, 0.3)" }}
        >
          In year 2023, Malaysia has 53% Obese or overweight population (NHMS
          2023).
        </div>
      </div>
    </>
  );
};

export default HomeTips;
