// src/component/mission/sub/lifestyle-habit-history/LifestyleBatteryIndicator.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';

const LifestyleBatteryIndicator = ({ lifestyleScore }) => {
  // Calculate the battery fill width based on the lifestyle score
  const batteryFillWidth = `${Math.min(lifestyleScore, 100)}%`; // Ensure it doesn't exceed 100%

  return (
    <div className="battery-container">
      <div className="battery-body">
        <div className="zinc-cylinder">
          <FontAwesomeIcon icon={faBolt} className="lightning-bolt" />
        </div>
        <div className="battery-fill" style={{ width: batteryFillWidth }}></div>
      </div>
      <div className="battery-tip"></div>
    </div>
  );
};

export default LifestyleBatteryIndicator;
