import PouchDB from "pouchdb";

const navigationDB = new PouchDB("navigationDB");

export const addNavigationItem = async (item) => {
  try {
    const existingItem = await navigationDB.get(item._id).catch((err) => {
      if (err.status === 404) {
        return null;
      }
      // throw err;
    });
    if (existingItem) {
      item._rev = existingItem._rev;
    }
    await navigationDB.put(item);
  } catch (error) {
    if (error.name === "conflict") {
      try {
        const resolvedItem = await navigationDB.get(item._id);
        item._rev = resolvedItem._rev;
        await navigationDB.put(item);
      } catch (retryError) {
        console.log(retryError);
      }
    } else {
      console.log(error);
    }
  }
};

export const getNavigationItems = async () => {
  try {
    const result = await navigationDB.allDocs({ include_docs: true });
    return result.rows.map((row) => row.doc);
  } catch (error) {
    console.error("Error fetching navigation items:", error);
    // throw error;
  }
};

export const deleteNavigationItem = async (id) => {
  try {
    const item = await navigationDB.get(id);
    const response = await navigationDB.remove(item);
    return response;
  } catch (error) {
    console.error("Error deleting navigation item:", error);
    // throw error;
  }
};

export default navigationDB;
