import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
import { getLocalDateOnly } from "../../util/Util"; // Assuming you have this utility function

PouchDB.plugin(PouchDBFind);

const levelUpDB = new PouchDB("levelUpDB");

// Create an index on userEmail and levelUpDateTime
levelUpDB.createIndex({
  index: { fields: ["userEmail", "levelUpDateTime"] }
}).then(() => {
  console.log("Index created on userEmail and levelUpDateTime");
}).catch((err) => {
  console.error("Error creating index:", err);
});

export const addLevelUp = async (userEmail, level) => {
  try {
    const levelUp = {
      _id: `${userEmail}-Level-${level}`,
      userEmail,
      levelUpDate: getLocalDateOnly(),
      levelUpDateTime: new Date().toISOString()
    };

    // Try to add the document
    try {
      await levelUpDB.put(levelUp);
      console.log("Level up added successfully");
      return levelUp;
    } catch (putError) {
      // If there's a conflict, resolve it
      if (putError.name === 'conflict') {
        return await handleLevelUpConflict(levelUp);
      } else {
        throw putError;
      }
    }
  } catch (error) {
    console.error("Error adding level up:", error);
    throw error;
  }
};

const handleLevelUpConflict = async (newLevelUp) => {
  try {
    // Fetch the existing document
    const existingDoc = await levelUpDB.get(newLevelUp._id);

    // Implement your conflict resolution strategy here
    // For example, keep the document with the earlier levelUpDateTime
    if (new Date(newLevelUp.levelUpDateTime) < new Date(existingDoc.levelUpDateTime)) {
      // The new document is earlier, so update the existing one
      const updatedDoc = {
        ...existingDoc,
        ...newLevelUp,
        _rev: existingDoc._rev // Include the revision of the existing document
      };
      await levelUpDB.put(updatedDoc);
      console.log("Conflict resolved: Updated with earlier level up");
      return updatedDoc;
    } else {
      // The existing document is earlier or same, so keep it
      console.log("Conflict resolved: Kept existing level up");
      return existingDoc;
    }
  } catch (error) {
    console.error("Error handling level up conflict:", error);
    throw error;
  }
};

export const getLastLevelUp = async (userEmail) => {
  try {
    const result = await levelUpDB.find({
      selector: {
        userEmail: userEmail,
        levelUpDateTime: { $exists: true },
      },
      sort: [{userEmail: 'asc'},{ levelUpDateTime: 'desc' }],
      limit: 1
    });
    return result.docs[0] || null;
  } catch (error) {
    console.error("Error getting last level up:", error);
    throw error;
  }
};

export const getLevelUpByLevel = async (userEmail, level) => {
  try {
    const result = await levelUpDB.get(`${userEmail}-Level-${level}`);
    return result;
  } catch (error) {
    if (error.name === 'not_found') {
      return null;
    }
    console.error("Error getting level up by level:", error);
    throw error;
  }
};

export default levelUpDB;
