import React from 'react';
import { formatDistanceToNow } from 'date-fns';

const MealDetailModal = ({ meal, onClose }) => {
  if (!meal) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded shadow-lg max-w-md w-full relative">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-600">
          &times;
        </button>
        <h2 className="text-2xl font-bold mb-4 text-center">{meal.mealType}</h2>
        <div className="flex justify-center mb-4">
          <img
            src={meal.mealAttachmentB64Array[0]}
            alt="Meal"
            className="max-h-40vh w-auto h-auto"
            style={{ maxHeight: '40vh' }}
          />
        </div>
        <p className="text-gray-600 mb-2">{formatDistanceToNow(new Date(meal.mealDateTime))} ago</p>
        <p className="text-gray-600 mb-2">{Math.round(meal.mealCalories)} Calories</p>
        <p className="text-gray-600">{meal.mealSentence}</p>
      </div>
    </div>
  );
};

export default MealDetailModal;