import { useState, useEffect, useMemo } from 'react';
import vxInstance from '../api/VxApiService';
import { useNavigate } from 'react-router-dom';
import { addUserObj, getUserObj } from '../store/pouch/user-pouch';
import { userState } from '../atom/atoms';
import { useRecoilState } from 'recoil';

const useTokenCheckVx = (version = "v0") => {
  const [recoilUser, setRecoilUser] = useRecoilState(userState);
  const [tokenCheckResult, setTokenCheckResult] = useState({
    isChecked: false,
    isValid: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      
      let user = await getUserObj("active");
      if (!user?.accessToken || !user?.refreshToken) {
        setTokenCheckResult({ isChecked: true, isValid: false });
        navigate('/signup-login');
        return;
      }
      try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 10000);

        vxInstance.checkTokenRole(user?.accessToken, user?.refreshToken, controller.signal)
        .then(async (result) => {
          
          if (result?.access_token && result?.refresh_token) {
            // Update user state with new tokens
            user = {
              ...user,
              accessToken: result?.access_token,
              refreshToken: result?.refresh_token
            };

            await addUserObj(user);
            let newUserObj = {...user, _id: user?.userEmail};
            await addUserObj(newUserObj);
            setRecoilUser(newUserObj);
          }

          setTokenCheckResult({ isChecked: true, isValid: true });
        });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Request was aborted');
        } else if (error.response) {
          // Server responded with a status other than 2xx
          console.error('Authentication failed:', error);
          navigate('/signup-login');
        } else {
          
          // Network error or other issues
          console.error('Token check failed:', error);
        }
        setTokenCheckResult({ isChecked: true, isValid: false });
      }
    };

    checkToken();
  }, [navigate, version]);

  const isApiConnected = useMemo(() => tokenCheckResult.isValid, [tokenCheckResult.isValid]);
  const isLoading = useMemo(() => !tokenCheckResult.isChecked, [tokenCheckResult.isChecked]);

  return { isApiConnected, isLoading };
};

export default useTokenCheckVx;