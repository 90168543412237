import { getUserDB } from '../store/pouch/user-pouch';
import { surveyQDB } from '../store/pouch/survey-questions-pouch';

export const iterateAllUsers = async () => {
  try {
    const allDocs = await getUserDB().allDocs({ include_docs: true });
    debugger;
    for (const row of allDocs.rows) {
      const user = row.doc;

      // getQuestionResponsesByUserEmail(
      //   user?.userEmail
      //   || 
      //   user?.value?.userEmail 
      // );
      // Your implementation here
      // You can access each user's data through the 'user' object
      // For example: user.userEmail, user.userGender, user.userEthnicity, etc.
      
    }
    
    // You can return a result here if needed
    
  } catch (error) {
    console.error("Error iterating users:", error);
    throw error;
  }
};

export const getQuestionResponsesByUserEmail = async (userEmail) => {
  try {
    const allDocs = await surveyQDB.allDocs({ include_docs: true });
    const userResponses = [];

    for (const row of allDocs.rows) {
      const question = row.doc;
      if (question.responses && question.responses[userEmail]) {
        userResponses.push({
          questionId: question._id,
          questionText: question.questionText,
          response: question.responses[userEmail]
        });
      }
    }

    return userResponses;
  } catch (error) {
    console.error("Error getting question responses for user:", error);
    throw error;
  }
};




