import { useRecoilState, useRecoilValue } from "recoil";

import QNum100 from "./qNum/QNum0100";
import QNum200 from "./qNum/QNum0200";
import QNum300 from "./qNum/QNum0300";
import QNum400 from "./qNum/QNum0400";
import QNum500 from "./qNum/QNum0500";
import QNum600 from "./qNum/QNum0600";
import QNum700 from "./qNum/QNum0700";
import QNum800 from "./qNum/QNum0800";
import QNum900 from "./qNum/QNum0900";
import QNum1000 from "./qNum/QNum1000";
import { currentQuestionState } from "../../atom/survey/survey-atoms";
import { useEffect } from "react";
import vxInstance from "../../api/VxApiService";
import { userState } from "../../atom/atoms";
import { addSurveyQuestion } from "../../store/pouch/survey-questions-pouch";
import { updateUserNickname } from "../../store/pouch/user-pouch";

const SurveyQuestions = () => {
  const currentQuestion = useRecoilValue(currentQuestionState);

  return (
    <>
      <div className="flex justify-center mt-4"></div>
      {(currentQuestion === "0" || currentQuestion === "100") && <QNum100 />}
      {currentQuestion === "200" && <QNum200 />}
      {currentQuestion === "300" && <QNum300 />}

      {currentQuestion === "400" && <QNum400 />}
      {currentQuestion === "500" && <QNum500 />}

      {currentQuestion === "600" && <QNum600 />}

      {currentQuestion === "700" && <QNum700 />}
      {currentQuestion === "800" && <QNum800 />}
      {currentQuestion === "900" && <QNum900 />}
      {currentQuestion === "1000" && <QNum1000 />}
    </>
  );
};

export default SurveyQuestions;
