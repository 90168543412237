//src/component/start/Start.js
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { addNavigationItem } from "../../store/pouch/navigation-pouch";
import "./Start.css";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  completedStartScreenState,
  stateOfIntroductionState,
  userState,
} from "../../atom/atoms";
import { surveyCompletionState } from "../../atom/survey/survey-atoms";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faHandPointLeft } from '@fortawesome/free-solid-svg-icons';

const Start = () => {
  const location = useLocation();
  const user = useRecoilValue(userState);
  const completedStartScreen = useRecoilValue(completedStartScreenState);
  const stateOfIntroduction = useRecoilValue(stateOfIntroductionState);
  const surveyCompletion = useRecoilValue(surveyCompletionState);
  const setCompleteStartScreen = useSetRecoilState(completedStartScreenState);
  const divideSpeed = 1;
  const [fadeStage, setFadeStage] = useState("fade-in");
  const [showLogo, setShowLogo] = useState(true);
  const [showWelcome, setShowWelcome] = useState(false);
  const [showSwipeUp, setShowSwipeUp] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [showScrollDown, setShowScrollDown] = useState(false);
  const [animationActive, setAnimationActive] = useState(true);

  const navigate = useNavigate();
  const handleClick = async () => {
    const navigateAndClear = (path) => {
      navigate(path, { 
        replace: true, 
        state: { clearSearch: true } 
      });
    };
    const item = {
      _id: "isCompleteStartScreen",
      value: true,
    };
    try {
      await addNavigationItem(item);
    } catch (error) {
      console.log("Error adding navigation item:", error);
    }
    setCompleteStartScreen(true);

    setIsFadingOut(true);
    setTimeout(() => {
      
      if (!surveyCompletion && stateOfIntroduction !== "20") {
        navigateAndClear("/testchat");
      } else if (!user) {
        navigateAndClear("/signup-login");
      } else if (!surveyCompletion) {
        navigateAndClear("/survey");
      } else {
        navigateAndClear("/daily");
      }
    }, 1000);
  };

  useEffect(() => {
    const fadeInTimer = setTimeout(() => {
      setFadeStage("visible");
    }, 1000 / divideSpeed);

    const fadeOutTimer = setTimeout(() => {
      setShowLogo(false);
      setShowWelcome(true);
    }, 2000 / divideSpeed);

    const swipeUpTimer = setTimeout(() => {
      setShowSwipeUp(true);
    }, 4000 / divideSpeed);


    const scrollDownTimer = setTimeout(() => {
      if (animationActive) {
        setShowScrollDown(true);
      }
    }, 5000);

    const handleScroll = () => {
      setShowScrollDown(false);
      setAnimationActive(false);
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('touchstart', handleScroll);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('touchstart', handleScroll);

    return () => {
      clearTimeout(fadeInTimer);
      clearTimeout(fadeOutTimer);
      clearTimeout(swipeUpTimer);
      clearTimeout(scrollDownTimer);
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('touchstart', handleScroll);
    };
  }, [animationActive]);

  return (
    <>
      <div className={`tailwind-reset container ${fadeStage}`}>
        {showLogo && (
          <div className="logo-container">
            <img
              src={`/healthChat.jpg`}
              alt="HealthChat Logo"
              className="logo"
            />
            <div className="stardust">
              <img src={`${process.env.PUBLIC_URL}/thumbnail/stardust.svg`} alt="Stardust" />
            </div>
            <div className="logo-text">healthChat</div>
          </div>
        )}
        {!showLogo && (
          <div className="nature-fade-in background-svg image-container">
            <img
              className=""
              src={`${process.env.PUBLIC_URL}/start2/01-welcome-nature.svg`}
              alt="Welcome Nature"
            />
          </div>
        )}
        {showWelcome && (
          <div className="welcome-text">
            <img src={`${process.env.PUBLIC_URL}/start2/01.1-Welcome.svg`} alt="Welcome to healthChat" />
          </div>
        )}
        {/* {showSwipeUp && (
          <>
            <div className="swipe-up-animation swipe-up-container">
              <span className="emoji-text">👇👇</span>
            </div>
          </>
        )} */}

        {showScrollDown && (
          <div className="scroll-down-container">
            <div className="scroll-down-text text-2xl">
              Scroll Down
            </div>
            <div className="scroll-down-text text-2xl">
              <FontAwesomeIcon 
                  icon={faArrowDown} />
            </div>
            <div className="finger-animation">
              <div className="finger">
                <FontAwesomeIcon 
                  icon={faHandPointLeft} 
                  className="finger ml-48 text-2xl text-blue-200" 
                />
              </div>
            </div>
          </div>
        )}

        {!showLogo && (
          <div className="sun">
            <img src={`${process.env.PUBLIC_URL}/start2/02.0-sun.svg`} alt="Sun" />
          </div>
        )}
      </div>
      {
        <>
          <div className="second-container">
            <div className="cluster">
              <img
                className="myname"
                src={`${process.env.PUBLIC_URL}/start2/02.1-myname.jpg`}
                alt="healthChat"
              />
              <div className="text-blue-500 text-3xl font-medium mt-4">I am healthChat</div>
              <img
                className=" hide-in-mobile"
                alt="healthChat"
                src={`${process.env.PUBLIC_URL}/thumbnail/svg256.svg`}
              />
            </div>
            <div className="cluster">
              <img
                className="myname"
                alt="healthChat"
                src={`${process.env.PUBLIC_URL}/start2/03.1-healthy-eating.jpg`}
              />
            </div>

            <div className="cluster">
              <img
                src={`${process.env.PUBLIC_URL}/start2/03.2-healthchat-full.svg`}
                alt="Healthy Eating And Lifestyle Transformation Hub"
              />
            </div>
            <div className="cluster">
              <img className="mb-3"
                alt="healthChat"
                src={`${process.env.PUBLIC_URL}/start2/03.3-bot-exercise.jpg`}
              />
              <div className="max-width-256 myname text-blue-500 font-medium text-3xl mb-3 text-center ">
                I am your Fat Loss and Exercise Assistant Chatbot
              </div>
              <div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </div>
            </div>

          </div>
          <div className="bottom-cluster">
            <button
              onClick={handleClick}
              className={`px-6 py-3 font-bold text-white rounded-lg shadow-lg shadow-blue-500/15 transition-opacity duration-1000 ${
                isFadingOut ? "opacity-0" : "opacity-100"
              }  bg-blue-400  mt-5 mx-auto block`}
            >
              Get Started Now
            </button>
            <div
              className={`version-text`}
            >{`${process.env.REACT_APP_VERSION}`}</div>
          </div>
          {showScrollDown && (
            <div className="overlay"></div>
          )}
        </>
      }
    </>
  );
};

export default Start;
