import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
PouchDB.plugin(PouchDBFind);

export const lifestyleQDB = new PouchDB("lifestyleQDB");

export const addLifestyleQuestion = async (question) => {
  try {
    const response = await lifestyleQDB.put(question);
    return response;
  } catch (error) {
    if (error.name === "conflict") {
      try {
        const existingQuestion = await lifestyleQDB.get(question._id);
        question._rev = existingQuestion._rev;

        const response = await lifestyleQDB.put(question);
        return response;
      } catch (retryError) {
        throw retryError;
      }
    } else {
      throw error;
    }
  }
};

export const getLifestyleQuestionsByUserEmailAndDateTimeRange = async (
  userEmail,
  startDateTime = null,
  endDateTime = null,
) => {
  try {
    const allDocs = await lifestyleQDB.allDocs({
      include_docs: true,
    });

    const filteredDocs = allDocs.rows.filter((row) => {
      const doc = row.doc;
      const docDate = new Date(doc.lifestyleQuestionDate);
      return (
        doc.userEmail === userEmail &&
        (startDateTime === null || docDate >= new Date(startDateTime)) &&
        (endDateTime === null || docDate <= new Date(endDateTime))
      );
    });

    console.log(
      "LifestyleQuestions retrieved for user within date range:",
      filteredDocs.map(row => row.doc),
    );
    return filteredDocs.map(row => row.doc);
  } catch (error) {
    console.error(
      "Error retrieving lifestyle for user within date range:",
      error,
    );
    return [];
  }
};

export const getLifestyleQuestion = async (id) => {
  try {
    const question = await lifestyleQDB.get(id);
    return question;
  } catch (error) {
    if (error.status === 404) {
      return null;
    }
    throw error;
  }
};

export const getLifestyleQuestionsByIds = async (ids) => {
  try {
    const result = await lifestyleQDB.allDocs({
      include_docs: true,
      keys: ids,
    });
    return result.rows.filter((row) => row.doc).map((row) => row.doc);
  } catch (error) {
    console.error("Error fetching lifestyle questions:", error);
    throw error;
  }
};

export const deleteLifestyleQuestion = async (id) => {
  try {
    const question = await lifestyleQDB.get(id);
    const response = await lifestyleQDB.remove(question);
    return response;
  } catch (error) {
    throw error;
  }
};
