import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import { tipsAtom } from "../../atom/tips/tips-atom";
import Typewriter from "typewriter-effect";
import "./Tips.css";

const Tips = () => {
  const [tipsState, setTipsState] = useRecoilState(tipsAtom);

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(tipsState.isVisible);
  }, [tipsState, setTipsState]);

  const handleCloseTips = () => {
    setTipsState({ ...tipsState, isVisible: false });
  };

  return (
    <>
    {visible && (<>
      <div
        className={`tips-overlay ${visible ? "visible" : ""}`}
        onClick={handleCloseTips}
      >
        <div className={`chatbot-container-t ${visible ? "visible" : ""}`}>
          <img
            src={`${process.env.PUBLIC_URL}/chat2/06-cute-chatbot-portrait.png`}
            alt="Chatbot"
            className="chatbot-image"
          /> 

          <button className="chatbot-close-button" onClick={handleCloseTips}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className="text-blue-400 text-xl text-medium ">
          <Typewriter
                    options={{
                      strings: "Im so sad to see you go.",
                      autoStart: true,
                      loop: false,
                      deleteSpeed: Infinity,
                      cursor: "",
                    }}
                  />
          </div>
        </div>
      </div>
    </>)}
    </>
  );
};

export default Tips;
