import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
PouchDB.plugin(PouchDBFind);

export const habitQDB = new PouchDB("habitQDB");

export const addHabitQuestion = async (question) => {
  try {
    const response = await habitQDB.put(question);
    return response;
  } catch (error) {
    if (error.name === "conflict") {
      try {
        const existingQuestion = await habitQDB.get(question._id);
        question._rev = existingQuestion._rev;

        const response = await habitQDB.put(question);
        return response;
      } catch (retryError) {
        throw retryError;
      }
    } else {
      throw error;
    }
  }
};

export const getHabitQuestionsByUserEmailAndDateTimeRange = async (
  userEmail,
  startDateTime = null,
  endDateTime = null,
) => {
  try {
    const allDocs = await habitQDB.allDocs({
      include_docs: true,
    });

    const filteredDocs = allDocs.rows.filter((row) => {
      const doc = row.doc;
      const docDate = new Date(doc.habitQuestionDate);
      return (
        doc.userEmail === userEmail &&
        (startDateTime === null || docDate >= new Date(startDateTime)) &&
        (endDateTime === null || docDate <= new Date(endDateTime))
      );
    });

    console.log(
      "HabitQuestions retrieved for user within date range:",
      filteredDocs.map(row => row.doc),
    );
    return filteredDocs.map(row => row.doc);
  } catch (error) {
    console.error("Error retrieving habit for user within date range:", error);
    return [];
  }
};

export const getHabitQuestion = async (id) => {
  try {
    const question = await habitQDB.get(id);
    return question;
  } catch (error) {
    if (error.status === 404) {
      return null;
    }
    throw error;
  }
};

export const getHabitQuestionsByIds = async (ids) => {
  try {
    const result = await habitQDB.allDocs({
      include_docs: true,
      keys: ids,
    });
    return result.rows.filter((row) => row.doc).map((row) => row.doc);
  } catch (error) {
    console.error("Error fetching habit questions:", error);
    throw error;
  }
};

export const deleteHabitQuestion = async (id) => {
  try {
    const question = await habitQDB.get(id);
    const response = await habitQDB.remove(question);
    return response;
  } catch (error) {
    throw error;
  }
};
