import React, { useState, useMemo } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { currentQuestionState } from "../../../atom/survey/survey-atoms";
import { questionAnswerState } from "../../../atom/survey/survey-questions-atoms";
import Typewriter from "typewriter-effect";
import { addSurvey } from "../../../store/pouch/survey-pouch";
import { userState } from "../../../atom/atoms";
import { addSurveyQuestion, iterateAllSurveyQuestionsByUserEmail } from "../../../store/pouch/survey-questions-pouch";
import { getUserObj, updateUserAgeGroup } from "../../../store/pouch/user-pouch";
import useTokenCheckVx from "../../../hooks/useTokenCheckVx";

import vxInstance from "../../../api/VxApiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBirthdayCake, faCalendar } from "@fortawesome/free-solid-svg-icons";

const QNum300 = () => {
  const [user, setUser] = useRecoilState(userState);
  const [currentQuestion, setCurrentQuestion] =
    useRecoilState(currentQuestionState);
  const [ageGroupAnswer, setAgeGroupAnswer] = useRecoilState(
    questionAnswerState("300"),
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isApiConnected, isLoading } = useTokenCheckVx();

  const canSyncWithServer = useMemo(() => isApiConnected && !isLoading, [isApiConnected, isLoading]);

  const selectedAgeGroup = ageGroupAnswer?.surveyAnswerString || "";

  const handleAgeGroupSelect = async (ageGroup) => {
    const answerJson = {
      _id: `${user?.userEmail}-surveyQNum-300`,
      surveyQuestionNumber: "300",
      surveyAnswerString: ageGroup,
      surveyAnswerList: [],
      userEmail: user?.userEmail,
    };

    try {
      await addSurveyQuestion(answerJson);
      await updateUserAgeGroup(user?.userEmail, ageGroup);
      
      setAgeGroupAnswer(answerJson);
      setIsModalOpen(false);

      // Update local user state
      setUser(prevUser => ({
        ...prevUser,
        userAgeGroup: ageGroup
      }));

      // Automatically call handleNext after selecting age group
      await handleNext();
    } catch (error) {
      console.error("Error saving survey question or updating user age group:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleBack = () => {
    setCurrentQuestion("200");
  };

  const handleNext = async () => {
    try {
      let userSurveyProgress = {
        currentQuestion: "400",
        isComplete: false,
        completionDateTime: null,
        earnedCoinAmount: 0,
        earnedCoinDatetime: null,
      };

      await addSurvey({ _id: user?.userEmail, ...userSurveyProgress });

      if (canSyncWithServer) {
        try {
          let allQuestions = await iterateAllSurveyQuestionsByUserEmail(user?.userEmail);
          const userQuestions = allQuestions.filter(q => q.userEmail === user?.userEmail);
          vxInstance.surveyClientToServerUpsert(user?.accessToken, user?.refreshToken, {surveyData: userQuestions});
          let userObj = await getUserObj(user?.userEmail);
          vxInstance.userClientToServerUpsert(user?.accessToken, user?.refreshToken, userObj);
          console.log("Survey data and User data synchronized with server");
        } catch (apiError) {
          console.error("Failed to synchronize with server:", apiError);
          // We don't throw here to allow offline progression
        }
      } else {
        console.log("Offline mode or loading: Survey data saved locally");
      }

      setCurrentQuestion("400");
    } catch (error) {
      console.error("Error handling next:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  return (
    <>
      <div className="flex justify-center mt-1">
        <img
          src={`${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`}
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover"
        />
      </div>
      <div className="flex justify-center mt-4">
        <div className="flex items-center justify-between ">
          {/* faBirthdayCake */}
          <span className="mr-2">
            <FontAwesomeIcon icon={faBirthdayCake} className="text-blue-400" /> 
          </span>
          {selectedAgeGroup && <span className="text-blue-500">How many birthdays you had?</span>}
          {!selectedAgeGroup && (
            <div className="text-blue-500">
            <Typewriter
              options={{
                strings: [`How many birthdays you had?`],
                autoStart: true,
                loop: false,
                deleteSpeed: Infinity,
                cursor: "", delay: 25,
              }}
            />
            </div>
          )}
          {/* faCalendar */}
          <span className="ml-2">
            <FontAwesomeIcon icon={faCalendar} className="text-blue-400" />
          </span>
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <button
          type="button"
          onClick={() => setIsModalOpen(true)}
          className={`px-4 py-2 rounded ${
            selectedAgeGroup
              ? "border border-blue-500 bg-white text-blue-500"
              : "bg-blue-500 text-white"
          }`}
        >
          {selectedAgeGroup || "Select Age Group"}
        </button>
      </div>
        <div className="flex justify-center mt-2">
          <button
            type="button"
            onClick={handleBack}
            className="mt-2 px-4 py-2 bg-gray-500 text-white rounded mr-2"
            >
            Back
          </button>
          {selectedAgeGroup && (
            <button
              type="button"
              onClick={handleNext}
              className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
            >
              Next
            </button>
          )}
        </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Select Your Age Group</h2>
            <div className="flex flex-col space-y-2">
              {["≤20", "21-30", "31-40", "41-50", "51-60", "≥61"].map(
                (ageGroup) => (
                  <button
                    key={ageGroup}
                    onClick={() => handleAgeGroupSelect(ageGroup)}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    {ageGroup}
                  </button>
                ),
              )}
            </div>
            <button
              onClick={() => setIsModalOpen(false)}
              className="mt-4 bg-gray-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default QNum300;